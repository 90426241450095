var $schema = "https://schemas.wp.org/trunk/block.json";
var version = "1.0";
var name = "constellation/mortgage-rates-summary";
var title = "Mortgage Rates Summary";
var description = "mortgage rates summary";
var icon = "businessperson";
var keywords = ["mortgage", "rates", "summary"];
var supports = {
  customClassName: false,
  html: false
};
var category = "more";
var attributes = {
  pathname: {
    type: "string",
    label: "Path",
    "default": "/mortgage-rates/",
    control: "text"
  }
};
var json = {
  $schema: $schema,
  version: version,
  name: name,
  title: title,
  description: description,
  icon: icon,
  keywords: keywords,
  supports: supports,
  category: category,
  attributes: attributes
};

export { $schema, attributes, category, json as default, description, icon, keywords, name, supports, title, version };
