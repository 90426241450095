function getTrackingParamsFromCurrentUrl() {
  var _document$referrer;
  var params = new URLSearchParams(window.location.search);
  var currentTrackingValues = {
    utm_medium: params.get('utm_medium'),
    utm_term: params.get('utm_term'),
    utm_content: params.get('utm_content'),
    utm_source: params.get('utm_source'),
    utm_campaign: params.get('utm_campaign'),
    Internal_Tracking_Code: params.get('itc'),
    URL: window.location.origin + window.location.pathname,
    URL_Referrer: ((_document$referrer = document.referrer) === null || _document$referrer === void 0 ? void 0 : _document$referrer.split('?')[0]) || ''
  };
  if (params.has('gclid')) {
    Object.assign(currentTrackingValues, {
      utm_medium: 'cpc',
      utm_source: 'google',
      utm_campaign: 'zbw_',
      utm_term: params.get('semAdid') + "|" + params.get('semKwid') + "|" + params.get('semAdgid')
    });
  }
  return currentTrackingValues;
}

export { getTrackingParamsFromCurrentUrl };
