import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
        scroll-behavior: smooth;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    iframe {
        border: none;
    }
    body {
        margin: 0;
        overflow-x: hidden;
    }
    img {
        max-width: 100%;
        height: auto;
    }
`;

export default GlobalStyle;
