import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import _mapValues from 'lodash/mapValues';
import _isPlainObject from 'lodash/isPlainObject';
import _isEmpty from 'lodash/isEmpty';
import ThemeCmsConstellation from '../../themes/ThemeCmsConstellation.js';

function prefixSpacingTokens(marginsOrPaddings) {
  if (!marginsOrPaddings) {
    return undefined;
  }
  var res = _mapValues(renameResponsiveTokens(marginsOrPaddings), function mapIt(value) {
    if (_isPlainObject(value)) {
      return _mapValues(value, mapIt);
    }
    if (Object.keys(ThemeCmsConstellation.constellation.spacing).includes(value)) {
      return "$spacing." + value;
    }
    return value;
  });
  return res;
}
function renameResponsiveTokens(responsiveStyles) {
  var nextStyles = {
    '@media $lg_lte': {},
    '@media $sm_lte': {}
  };
  Object.entries(responsiveStyles).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      styleName = _ref2[0],
      styleValueOrResponsiveStyleValues = _ref2[1];
    if (_isPlainObject(styleValueOrResponsiveStyleValues)) {
      // Shapes is:
      // {
      //   marginTop: {
      //     default: '$spacing.xl',
      //     lg_lte: '$spacing.sm'
      //   }
      // }
      var responsiveStyleValues = styleValueOrResponsiveStyleValues;
      Object.entries(responsiveStyleValues).forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          mediaQuery = _ref4[0],
          styleValue = _ref4[1];
        if (mediaQuery === 'default') {
          nextStyles[styleName] = styleValue;
        } else {
          // mediaQuery is not "default", it is something like "lg_lte".
          nextStyles["@media $" + mediaQuery][styleName] = styleValue;
        }
      });
    } else {
      // Shape is:
      // {
      //   marginTop: 'xl'
      // }
      var styleValue = styleValueOrResponsiveStyleValues;
      nextStyles[styleName] = styleValue;
    }
  });

  // Not needed, just makes it cleaner.
  if (_isEmpty(nextStyles['@media $lg_lte'])) {
    delete nextStyles['@media $lg_lte'];
  }
  if (_isEmpty(nextStyles['@media $sm_lte'])) {
    delete nextStyles['@media $sm_lte'];
  }
  return nextStyles;
}

export { prefixSpacingTokens };
