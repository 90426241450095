import React, { useState } from 'react';
import { Banner, CloseButton } from '@zillow/constellation';
import { yieldCallback } from '@zillow/yield-callback';
import styled from 'styled-components';

const StyledBanner = styled(Banner)`
    position: fixed;
    bottom: 2.5rem;

    /* Take full width but centere it with 2.5% horizontal margins. */
    left: 2.5%;
    /*  2.5% * 2 is 5% and 100% - 5% is 95%. */
    width: 95%;

    /* Copied from Toast */
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);

    /* Using 1,000 puts it above Zillow header. */
    z-index: 1000;
`;

function PreviewModeAlert() {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <StyledBanner
            appearance="warning"
            body="You are viewing a preview of this content."
            isOpen={isOpen}
            closeButton={
                <CloseButton onClick={yieldCallback(() => setIsOpen(false))} />
            }
        />
    );
}

export default PreviewModeAlert;
