var installations = [{
  label: "Adelphi Laboratory Center",
  value: "869242"
}, {
  label: "Air Force Maui Optical and Supercomputing Site",
  value: "869479"
}, {
  label: "Air Force Office of Scientific Research",
  value: "869435"
}, {
  label: "Air Force Plant 4",
  value: "869486"
}, {
  label: "Air Force Plant 42",
  value: "869488"
}, {
  label: "Air Force Plant 44",
  value: "869483"
}, {
  label: "Air Force Plant 6",
  value: "869334"
}, {
  label: "ALF Bogue",
  value: "869412"
}, {
  label: "ALF Cabaniss",
  value: "868960"
}, {
  label: "ALF Fentress Chesapke",
  value: "868845"
}, {
  label: "ALF Orange",
  value: "868980"
}, {
  label: "ALF Waldron",
  value: "868916"
}, {
  label: "Aliamanu Military Reservation",
  value: "869509"
}, {
  label: "Allegany Ballistics Lab",
  value: "868855"
}, {
  label: "Allen Stagefield AL",
  value: "869002"
}, {
  label: "Alpena County Regional Airport",
  value: "869366"
}, {
  label: "Altus AFB",
  value: "869497"
}, {
  label: "Amphib Base Coronado West",
  value: "868906"
}, {
  label: "Andersen Air Force Base",
  value: "869561"
}, {
  label: "Anniston Army Depot",
  value: "869003"
}, {
  label: "Arbuckle Airfield",
  value: "869438"
}, {
  label: "Arlington National Cemetery",
  value: "869005"
}, {
  label: "Arlington Service Ctr",
  value: "868833"
}, {
  label: "Army Futures Command",
  value: "869492"
}, {
  label: "Army Research Lab ? Orlando Simulations and Training Technology Center",
  value: "869494"
}, {
  label: "Army Research Lab ? Raleigh Durham",
  value: "869493"
}, {
  label: "Arnold Air Force Base",
  value: "869313"
}, {
  label: "Atlantic City IAP",
  value: "869377"
}, {
  label: "Avon Park AF Range",
  value: "869439"
}, {
  label: "Badger AAP",
  value: "869513"
}, {
  label: "Balboa Hospital",
  value: "868879"
}, {
  label: "Bangor IAP",
  value: "869385"
}, {
  label: "Barin Field",
  value: "868971"
}, {
  label: "Barksdale AFB",
  value: "869327"
}, {
  label: "Barnes Map ANG",
  value: "869485"
}, {
  label: "Barry Goldwater Range",
  value: "869433"
}, {
  label: "Barry M Goldwater Range",
  value: "869356"
}, {
  label: "Beale Air Force Base",
  value: "869564"
}, {
  label: "Bellows",
  value: "869346"
}, {
  label: "Biometric Technology Center (Biometrics Identity Management Activity)",
  value: "869495"
}, {
  label: "Birch Lake Recreation Annex",
  value: "869347"
}, {
  label: "Birmingham Airport",
  value: "869452"
}, {
  label: "Blue Grass Army Depot",
  value: "869016"
}, {
  label: "Boise Air Terminal",
  value: "869471"
}, {
  label: "Bradley IAP",
  value: "869453"
}, {
  label: "Bratenahl",
  value: "868847"
}, {
  label: "Bridgeport",
  value: "869410"
}, {
  label: "Broadway Complex",
  value: "868932"
}, {
  label: "Brooklyn NY",
  value: "869423"
}, {
  label: "Brooks AFB",
  value: "869482"
}, {
  label: "Brown 4b Stagefield AL",
  value: "869019"
}, {
  label: "Buckley Space Force Base",
  value: "869515"
}, {
  label: "Burlington IAP",
  value: "869469"
}, {
  label: "Cairns Basefield AL",
  value: "869020"
}, {
  label: "Camp Blas",
  value: "869563"
}, {
  label: "Camp Frank D Merrill",
  value: "869052"
}, {
  label: "Camp MacKall",
  value: "869032"
}, {
  label: "Camp Michael Monsoor",
  value: "868901"
}, {
  label: "Camp Morena",
  value: "868952"
}, {
  label: "Camp Shelby",
  value: "869163"
}, {
  label: "Cannon AFB",
  value: "869282"
}, {
  label: "Cannon AFB Site 2",
  value: "869284"
}, {
  label: "Cape Canaveral Space Force Station",
  value: "869516"
}, {
  label: "Cape Cod Space Force Station",
  value: "869293"
}, {
  label: "Cape Newenham Long Range Radar Site",
  value: "869331"
}, {
  label: "Capital Airport",
  value: "869394"
}, {
  label: "Carlisle Barracks",
  value: "869517"
}, {
  label: "Cavalier Space Force Station",
  value: "869310"
}, {
  label: "CBC Gulfport MS",
  value: "868917"
}, {
  label: "Channel Islands ANG Station",
  value: "869463"
}, {
  label: "Chanute AFB",
  value: "869487"
}, {
  label: "Charlotte/Douglas IAP",
  value: "869349"
}, {
  label: "Cheatham Annex",
  value: "868849"
}, {
  label: "Chesapeake Bay Detach",
  value: "868915"
}, {
  label: "Cheyenne Mountain Space Force Station",
  value: "869294"
}, {
  label: "Cheyenne Regional Airport",
  value: "869529"
}, {
  label: "Choc Mt Air Gnry Rng",
  value: "869434"
}, {
  label: "Clear Space Force Station",
  value: "869326"
}, {
  label: "CNI NAVMAG Indian Island",
  value: "868878"
}, {
  label: "Columbus AFB",
  value: "869354"
}, {
  label: "Columbus Auxiliary Airfield",
  value: "869353"
}, {
  label: "Corry Station",
  value: "868919"
}, {
  label: "Craney Island",
  value: "868842"
}, {
  label: "Creech Air Force Base",
  value: "869289"
}, {
  label: "CSO Barbers Point HI",
  value: "868904"
}, {
  label: "CSO NAS South Weymouth",
  value: "868993"
}, {
  label: "Cusick Survival Training Site",
  value: "869312"
}, {
  label: "Dania Beach (Lauderdale)",
  value: "869475"
}, {
  label: "Dare County Range",
  value: "869299"
}, {
  label: "Davis-Monthan Air Force Base",
  value: "869287"
}, {
  label: "Def Gen Supply Center",
  value: "869053"
}, {
  label: "Defense Advanced Research Projects Agency",
  value: "869496"
}, {
  label: "Defense Distrib Depot Susq",
  value: "869054"
}, {
  label: "Defense Supply Center Columbus",
  value: "869055"
}, {
  label: "Des Moines IAP",
  value: "869455"
}, {
  label: "Destin Moreno Point",
  value: "869057"
}, {
  label: "Det Concord (BRAC)",
  value: "868991"
}, {
  label: "Det Phil Pny Annex",
  value: "868860"
}, {
  label: "Detroit Arsenal",
  value: "869058"
}, {
  label: "Devens Reserve Forces Tng Area",
  value: "868995"
}, {
  label: "DFAS Annex",
  value: "869314"
}, {
  label: "Dillingham Mil Res",
  value: "869498"
}, {
  label: "Dixie Target Range",
  value: "868984"
}, {
  label: "Dobbins ARB",
  value: "869335"
}, {
  label: "Dover AFB",
  value: "869348"
}, {
  label: "Dredgers Key-Sigsbee",
  value: "868982"
}, {
  label: "Dryside",
  value: "868951"
}, {
  label: "Duluth IAP",
  value: "869364"
}, {
  label: "Dyess AFB",
  value: "869424"
}, {
  label: "Edwards Air Force Base",
  value: "869530"
}, {
  label: "Eglin AFB Site 2 Santa Rosa Island",
  value: "869304"
}, {
  label: "Eglin Air Force Auxiliary Field 3 Duke Fld",
  value: "869302"
}, {
  label: "Eglin Air Force Auxiliary Field 6",
  value: "869278"
}, {
  label: "Eglin Air Force Base",
  value: "869491"
}, {
  label: "Eielson Air Force Base",
  value: "869531"
}, {
  label: "Elizabeth Rvr Channel",
  value: "868843"
}, {
  label: "Ellington Field Joint Reserve Base",
  value: "869510"
}, {
  label: "Ellsworth AFB",
  value: "869321"
}, {
  label: "Ellsworth AFB Site 2",
  value: "869319"
}, {
  label: "England Authority",
  value: "869062"
}, {
  label: "EWVRA Shepherd Field",
  value: "869472"
}, {
  label: "Fairchild Air Force Base",
  value: "869317"
}, {
  label: "Fallbrook California",
  value: "868953"
}, {
  label: "Fallon Range Complex",
  value: "869540"
}, {
  label: "Fire Fighters School",
  value: "868854"
}, {
  label: "FLC Fuel Depot Heckscher",
  value: "868922"
}, {
  label: "Fleming Key Magazine",
  value: "868983"
}, {
  label: "Forbes Field ANG",
  value: "869454"
}, {
  label: "Former NTC",
  value: "869428"
}, {
  label: "Fort Adams RI",
  value: "868871"
}, {
  label: "Fort Belvoir",
  value: "869067"
}, {
  label: "Fort Benjamin Harrison",
  value: "869068"
}, {
  label: "Fort Bliss",
  value: "869532"
}, {
  label: "Fort Buchanan",
  value: "869071"
}, {
  label: "Fort Campbell",
  value: "868996"
}, {
  label: "Fort Carson",
  value: "869072"
}, {
  label: "Fort Cavazos",
  value: "869534"
}, {
  label: "Fort De Russy",
  value: "869499"
}, {
  label: "Fort Detrick",
  value: "869074"
}, {
  label: "Fort Detrick Forest Glen Annex",
  value: "869199"
}, {
  label: "Fort Detrick Glen Haven Annex",
  value: "869200"
}, {
  label: "Fort Drum",
  value: "869075"
}, {
  label: "Fort Eisenhower",
  value: "869076"
}, {
  label: "Fort Fisher Recreation Site",
  value: "869297"
}, {
  label: "Fort Gillem Enclave Site",
  value: "869272"
}, {
  label: "Fort Greely",
  value: "869077"
}, {
  label: "Fort Gregg-Adams",
  value: "869085"
}, {
  label: "Fort Hamilton",
  value: "869078"
}, {
  label: "Fort Huachuca",
  value: "869079"
}, {
  label: "Fort Hunter Liggett",
  value: "869080"
}, {
  label: "Fort Irwin",
  value: "869181"
}, {
  label: "Fort Jackson",
  value: "869082"
}, {
  label: "Fort Johnson",
  value: "869092"
}, {
  label: "Fort Knox",
  value: "869083"
}, {
  label: "Fort Leavenworth",
  value: "869084"
}, {
  label: "Fort Leonard Wood",
  value: "869086"
}, {
  label: "Fort Lewis",
  value: "869571"
}, {
  label: "Fort Liberty",
  value: "869070"
}, {
  label: "Fort McCoy",
  value: "869088"
}, {
  label: "Fort Meade",
  value: "869533"
}, {
  label: "Fort Monmouth Main Post",
  value: "869089"
}, {
  label: "Fort Monroe",
  value: "869090"
}, {
  label: "Fort Moore",
  value: "869069"
}, {
  label: "Fort Novosel AL",
  value: "869094"
}, {
  label: "Fort Riley",
  value: "869093"
}, {
  label: "Fort Shafter",
  value: "869535"
}, {
  label: "Fort Sheridan",
  value: "869536"
}, {
  label: "Fort Sill",
  value: "869095"
}, {
  label: "Fort Smith Map",
  value: "869370"
}, {
  label: "Fort Stewart",
  value: "869096"
}, {
  label: "Fort Wainwright",
  value: "869097"
}, {
  label: "Fort Walker",
  value: "869066"
}, {
  label: "Fort Wingate Depot Activity",
  value: "869098"
}, {
  label: "Fort Yukon Long Range Radar Site",
  value: "869328"
}, {
  label: "Fort Zillow",
  value: "868828"
}, {
  label: "Fort Zillow",
  value: "868828"
}, {
  label: "Francis E. Warren Air Force Base",
  value: "869325"
}, {
  label: "Francis S Gabreski Airport",
  value: "869390"
}, {
  label: "Fresno Yosemite International ANG",
  value: "869375"
}, {
  label: "Gambrills MD",
  value: "868834"
}, {
  label: "Gen Mitchell IAP",
  value: "869363"
}, {
  label: "General Wayne A Downing Peoria IAP",
  value: "869386"
}, {
  label: "George AFB",
  value: "869359"
}, {
  label: "GL Camp JPJ",
  value: "868857"
}, {
  label: "Glenview",
  value: "868846"
}, {
  label: "Goldberg Stagefield AL",
  value: "869104"
}, {
  label: "Goldwater ANGB",
  value: "869396"
}, {
  label: "Goodfellow AFB",
  value: "869350"
}, {
  label: "Gould Island RI",
  value: "868841"
}, {
  label: "Grand Forks AFB",
  value: "869320"
}, {
  label: "Grand Prairie Reserve Complex",
  value: "869106"
}, {
  label: "Great Falls IAP",
  value: "869376"
}, {
  label: "Green River Test Complex UT",
  value: "869108"
}, {
  label: "Grissom ARB",
  value: "869336"
}, {
  label: "Gulfport-Biloxi Regional Airport (ANG)",
  value: "869462"
}, {
  label: "Hancock Field",
  value: "869384"
}, {
  label: "Hanscom Air Force Base",
  value: "869303"
}, {
  label: "Harbor Drive",
  value: "869430"
}, {
  label: "Hardwood Range",
  value: "869392"
}, {
  label: "Hawthorne Army Depot",
  value: "869113"
}, {
  label: "Hector IAP",
  value: "869372"
}, {
  label: "Helemano Military Reservation",
  value: "869500"
}, {
  label: "Highbluff Stagefield AL",
  value: "869115"
}, {
  label: "Hill Air Force Base",
  value: "869280"
}, {
  label: "Holloman Air Force Base",
  value: "869441"
}, {
  label: "Holston Army Ammunition Plant",
  value: "869542"
}, {
  label: "Holtville Carrier LS",
  value: "868882"
}, {
  label: "Homestead ARB",
  value: "869338"
}, {
  label: "Hulman Fld",
  value: "869387"
}, {
  label: "Hunt Stagefield AL",
  value: "869118"
}, {
  label: "Hunter Army Airfield",
  value: "869119"
}, {
  label: "Hurlburt Field",
  value: "869307"
}, {
  label: "Imperial Beach NOLF",
  value: "868909"
}, {
  label: "Indiana Army Ammunition Plant",
  value: "869120"
}, {
  label: "Iowa AAP",
  value: "869122"
}, {
  label: "Isabela Puerto Rico",
  value: "869337"
}, {
  label: "Jackson IAP Thompson Field",
  value: "869459"
}, {
  label: "Jacksonville FL Maint",
  value: "869473"
}, {
  label: "Jacksonville IAP",
  value: "869382"
}, {
  label: "Jefferson Proving Ground",
  value: "869126"
}, {
  label: "Jim Creek",
  value: "869547"
}, {
  label: "Joe Foss Field",
  value: "869458"
}, {
  label: "Joint Base Anacostia-Bolling",
  value: "869565"
}, {
  label: "Joint Base Andrews",
  value: "869566"
}, {
  label: "Joint Base Charleston",
  value: "869555"
}, {
  label: "Joint Base Charleston North Field SBML",
  value: "869556"
}, {
  label: "Joint Base Elmendorf-Richardson",
  value: "869567"
}, {
  label: "Joint Base Langley-Eustis",
  value: "869569"
}, {
  label: "Joint Base McGuire-Dix-Lakehurst",
  value: "869544"
}, {
  label: "Joint Base Myer-Henderson Hall",
  value: "869570"
}, {
  label: "Joint Base Pearl Harbor-Hickam",
  value: "869560"
}, {
  label: "Joint Base San Antonio",
  value: "869290"
}, {
  label: "Joliet AAP Elwood",
  value: "869549"
}, {
  label: "Joliet USARC/JTA",
  value: "869244"
}, {
  label: "Kaena Point Space Force Station",
  value: "869490"
}, {
  label: "Kahuku Tng Area",
  value: "869129"
}, {
  label: "Kamokala Ridge",
  value: "869551"
}, {
  label: "Kawaihae Mil Reserve",
  value: "869131"
}, {
  label: "Keesler AFB",
  value: "869552"
}, {
  label: "Kegelman Air Force Auxiliary Field",
  value: "869352"
}, {
  label: "Kilauea Mil Reserve",
  value: "869501"
}, {
  label: "King Salmon Long Range Radar Site",
  value: "869554"
}, {
  label: "Kingsley Field Cantonement Site 1",
  value: "869550"
}, {
  label: "Kipapa Ammo Storage Site",
  value: "869132"
}, {
  label: "Kirtland Air Force Base",
  value: "869300"
}, {
  label: "Kodiak Tracking Station",
  value: "868869"
}, {
  label: "Lafayette Rvr Complex",
  value: "868866"
}, {
  label: "Lake City AAP",
  value: "869133"
}, {
  label: "Lake Of The Ozarks Recreation",
  value: "869134"
}, {
  label: "Laughlin AFB",
  value: "869306"
}, {
  label: "Laughlin AFB Aux 1",
  value: "869305"
}, {
  label: "Letterkenny Army Depot",
  value: "869139"
}, {
  label: "Lincoln Map",
  value: "869398"
}, {
  label: "Little Rock AFB",
  value: "869292"
}, {
  label: "Long Beach Fuel Complex",
  value: "868937"
}, {
  label: "Longhorn AAP",
  value: "869142"
}, {
  label: "Los Angeles Air Force Base",
  value: "869548"
}, {
  label: "Los Angeles Space Force Annex No4",
  value: "869449"
}, {
  label: "Louisville IAP-Standiford FL Site 1",
  value: "869464"
}, {
  label: "Louisville Stagefield AL",
  value: "869143"
}, {
  label: "Luis Munoz Marin IAP",
  value: "869457"
}, {
  label: "Luke AFB",
  value: "869546"
}, {
  label: "Luke Air Force Auxiliary Field No 1",
  value: "869355"
}, {
  label: "Luke Waste Annex",
  value: "869357"
}, {
  label: "MacDill Air Force Base",
  value: "869446"
}, {
  label: "Makaha Ridge",
  value: "868897"
}, {
  label: "Makua Mil Reserve",
  value: "869147"
}, {
  label: "Malmstrom Air Force Base",
  value: "869369"
}, {
  label: "Manchester",
  value: "868874"
}, {
  label: "Mansfield Lahm Airport ANG 179th AW",
  value: "869365"
}, {
  label: "March ARB",
  value: "869502"
}, {
  label: "Marine Corps Air Ground Combat Center Twentynine Palms",
  value: "869431"
}, {
  label: "Marine Corps Air Station Beaufort",
  value: "869407"
}, {
  label: "Marine Corps Air Station Cherry Point",
  value: "869415"
}, {
  label: "Marine Corps Air Station Miramar",
  value: "869422"
}, {
  label: "Marine Corps Air Station New River",
  value: "869421"
}, {
  label: "Marine Corps Air Station Yuma",
  value: "869432"
}, {
  label: "Marine Corps Base Camp Lejeune",
  value: "869545"
}, {
  label: "Marine Corps Base Camp Pendleton",
  value: "869559"
}, {
  label: "Marine Corps Base Hawaii",
  value: "869420"
}, {
  label: "Marine Corps Base Hawaii, Camp H.M. Smith",
  value: "869418"
}, {
  label: "Marine Corps Base Quantico",
  value: "869427"
}, {
  label: "Marine Corps Museum",
  value: "869484"
}, {
  label: "Mark Center",
  value: "869437"
}, {
  label: "Martin State Airport",
  value: "869460"
}, {
  label: "Marysville WA",
  value: "868877"
}, {
  label: "Maxwell AFB",
  value: "869442"
}, {
  label: "Maxwell AFB Gunter Annex",
  value: "869440"
}, {
  label: "MC Trng Area Bellows",
  value: "869416"
}, {
  label: "McAlester AAP",
  value: "869150"
}, {
  label: "McClellan AFB",
  value: "869368"
}, {
  label: "McConnell AFB",
  value: "869447"
}, {
  label: "McEntire Joint National Guard Base",
  value: "869373"
}, {
  label: "McGhee Tyson Airport",
  value: "869448"
}, {
  label: "McLaughlin ANGB",
  value: "869380"
}, {
  label: "MCLB Albany",
  value: "869404"
}, {
  label: "MCLB Barstow",
  value: "869405"
}, {
  label: "MCRD Parris Island",
  value: "869426"
}, {
  label: "MCRD San Diego",
  value: "869429"
}, {
  label: "MCSF Blount Island (FL)",
  value: "869409"
}, {
  label: "Melrose Air Force Range",
  value: "869285"
}, {
  label: "Memphis IAP",
  value: "869461"
}, {
  label: "Milan AAP",
  value: "869275"
}, {
  label: "Military Ocean Terminal Concord",
  value: "869048"
}, {
  label: "Military Ocean Tml Sunny Point",
  value: "869154"
}, {
  label: "Minn-St Paul",
  value: "869340"
}, {
  label: "Minot Air Force Base",
  value: "869374"
}, {
  label: "Mission Gorge Rec Area",
  value: "868889"
}, {
  label: "Montgomery Regional Airport ANGB",
  value: "869378"
}, {
  label: "Moody Air Force Base",
  value: "869543"
}, {
  label: "Mountain Home Air Force Base",
  value: "869281"
}, {
  label: "NAF El Centro",
  value: "868935"
}, {
  label: "NALF Goliad",
  value: "868981"
}, {
  label: "NARL Barrow Camp Tr1",
  value: "869541"
}, {
  label: "NAS Brunswick ME",
  value: "868994"
}, {
  label: "NAS Corpus Christi TX",
  value: "868966"
}, {
  label: "NAS Fort Worth JRB TX",
  value: "868968"
}, {
  label: "NAS Jacksonville FL",
  value: "868959"
}, {
  label: "NAS JRB Willow Grove PA",
  value: "868992"
}, {
  label: "NAS Key West FL",
  value: "868987"
}, {
  label: "NAS Kingsville TX",
  value: "868963"
}, {
  label: "NAS Lemoore CA",
  value: "868884"
}, {
  label: "NAS Pensacola FL",
  value: "868930"
}, {
  label: "NAS Whiting Field Milton FL",
  value: "868920"
}, {
  label: "Nashville IAP",
  value: "869451"
}, {
  label: "National City",
  value: "868892"
}, {
  label: "NAVACAD North Severn",
  value: "868940"
}, {
  label: "Naval Air Station Joint Reserve Base New Orleans",
  value: "868923"
}, {
  label: "Naval Air Station Meridian",
  value: "868942"
}, {
  label: "Naval Air Station Oceana",
  value: "868844"
}, {
  label: "Naval Air Station Oceana Dam Neck Annex",
  value: "868870"
}, {
  label: "Naval Air Station Patuxent River",
  value: "868958"
}, {
  label: "Naval Air Station Whidbey Island",
  value: "869539"
}, {
  label: "Naval Air Weapons Station China Lake",
  value: "868885"
}, {
  label: "Naval Base Kitsap - Keyport",
  value: "869553"
}, {
  label: "Naval Base Kitsap Bangor",
  value: "868876"
}, {
  label: "Naval Base Kitsap Bremerton WA",
  value: "869538"
}, {
  label: "Naval Base Point Loma",
  value: "868894"
}, {
  label: "Naval Base San Diego",
  value: "868898"
}, {
  label: "Naval Base Ventura County ? Point Mugu Operating Facility",
  value: "868896"
}, {
  label: "Naval Base Ventura County ? Port Hueneme Operating Facility",
  value: "868888"
}, {
  label: "Naval Observatory",
  value: "868947"
}, {
  label: "Naval Research Laboratory",
  value: "868944"
}, {
  label: "Naval Research Laboratory ? Blossom Point",
  value: "869015"
}, {
  label: "Naval Research Laboratory ? Stennis Space Center",
  value: "869476"
}, {
  label: "Naval Research Laboratory ? Tilghman",
  value: "868838"
}, {
  label: "Naval Station Great Lakes IL",
  value: "868851"
}, {
  label: "Naval Station Newport",
  value: "868868"
}, {
  label: "Naval Station Norfolk",
  value: "868848"
}, {
  label: "Naval Submarine Base Kings Bay",
  value: "868976"
}, {
  label: "Naval Submarine Base New London",
  value: "868836"
}, {
  label: "Naval Support Activity Crane",
  value: "868835"
}, {
  label: "Naval Support Activity Orlando",
  value: "868977"
}, {
  label: "Naval Support Activity Philadelphia",
  value: "868840"
}, {
  label: "Naval Support Activity Wash",
  value: "868832"
}, {
  label: "Naval Support Facility Carderock",
  value: "868913"
}, {
  label: "Naval Support Facility Dahlgren",
  value: "868945"
}, {
  label: "Naval Support Facility Indian Head",
  value: "868955"
}, {
  label: "Naval Surface Warfare Center Carderock Division ? Acoustic Research Detachment",
  value: "869514"
}, {
  label: "Naval Weapons Station Earle NJ",
  value: "868872"
}, {
  label: "Naval Weapons Station Seal Beach Detachment Norco",
  value: "868891"
}, {
  label: "Naval Weapons Station Yorktown",
  value: "868867"
}, {
  label: "Naval Weapons Systems Training Facility Boardman",
  value: "868873"
}, {
  label: "NAVBASE Coronado",
  value: "868973"
}, {
  label: "NAVBASE Guam",
  value: "869562"
}, {
  label: "NAVMEDCEN Portsmouth VA",
  value: "868829"
}, {
  label: "NAVOBSSTA Flagstaff AZ",
  value: "868883"
}, {
  label: "NAVPMOSSP Magna Utah",
  value: "868950"
}, {
  label: "NAVPMOSSP Mtn View",
  value: "868890"
}, {
  label: "NAVSTA Everett WA",
  value: "869537"
}, {
  label: "NAVSTA Mayport FL",
  value: "868962"
}, {
  label: "NAVSUPPACT Annapolis",
  value: "869474"
}, {
  label: "NAVSUPPACT Bethesda MD",
  value: "868839"
}, {
  label: "NAVSUPPACT Hampton Roads VA",
  value: "868830"
}, {
  label: "NAVSUPPACT Mechanicsburg PA",
  value: "868837"
}, {
  label: "NAVSUPPACT Midsouth Memphis TN",
  value: "868956"
}, {
  label: "NAVSUPPACT Monterey CA",
  value: "868938"
}, {
  label: "NAVSUPPACT Norfolk NSY",
  value: "868864"
}, {
  label: "NAVSUPPFAC Beaufort SC",
  value: "868929"
}, {
  label: "NAVWPNSTA Seal Beach",
  value: "868895"
}, {
  label: "Navy Annex",
  value: "868887"
}, {
  label: "Nellis Air Force Base",
  value: "869527"
}, {
  label: "Nevada Test and Training Range",
  value: "869528"
}, {
  label: "New Boston Space Force Station",
  value: "869450"
}, {
  label: "New Castle Airport",
  value: "869381"
}, {
  label: "Newington Defense Fuel Support Point",
  value: "869315"
}, {
  label: "NG AASF02 Birmingham",
  value: "869001"
}, {
  label: "NG Alcantra Armory Complex",
  value: "869174"
}, {
  label: "NG Anthony Cometa Complex",
  value: "869144"
}, {
  label: "NG Arden Hills Army Training Site",
  value: "869004"
}, {
  label: "NG Atlanta United Ave",
  value: "869006"
}, {
  label: "NG Auburn Training Site",
  value: "869007"
}, {
  label: "NG Ayer",
  value: "869258"
}, {
  label: "NG Bangor Training Site",
  value: "869008"
}, {
  label: "NG Baton Rouge AFRC",
  value: "869270"
}, {
  label: "NG Beauregard Training Range",
  value: "869009"
}, {
  label: "NG Beightler Armory",
  value: "869010"
}, {
  label: "NG Bend Cotef",
  value: "869011"
}, {
  label: "NG Bergstrom - (Abia)",
  value: "869012"
}, {
  label: "NG Bethany Beach Training Site",
  value: "869235"
}, {
  label: "NG Biak Training Center COUTES",
  value: "869013"
}, {
  label: "NG Bismarck RJB Complex",
  value: "869014"
}, {
  label: "NG Bog Brook Training Site",
  value: "869017"
}, {
  label: "NG Bremerton",
  value: "869018"
}, {
  label: "NG Buckeye TS",
  value: "869175"
}, {
  label: "NG Cambridge Springs Readiness Center and FMS 5",
  value: "869262"
}, {
  label: "NG Camp Adair Corvallis",
  value: "869021"
}, {
  label: "NG Camp Ashland",
  value: "869022"
}, {
  label: "NG Camp Atterbury",
  value: "869023"
}, {
  label: "NG Camp Beauregard",
  value: "869221"
}, {
  label: "NG Camp Bowie (State)",
  value: "869024"
}, {
  label: "NG Camp Dodge Johnston TS",
  value: "869025"
}, {
  label: "NG Camp Fogarty Tng Site",
  value: "869026"
}, {
  label: "NG Camp Grafton",
  value: "869027"
}, {
  label: "NG Camp Gruber",
  value: "869028"
}, {
  label: "NG Camp Hartell",
  value: "869029"
}, {
  label: "NG Camp Joseph T Robinson",
  value: "869030"
}, {
  label: "NG Camp Mabry",
  value: "869031"
}, {
  label: "NG Camp Maxey",
  value: "869033"
}, {
  label: "NG Camp Minden Training Site",
  value: "869034"
}, {
  label: "NG Camp Navajo",
  value: "869035"
}, {
  label: "NG Camp Nett",
  value: "869039"
}, {
  label: "NG Camp Orchard Cantonement Area",
  value: "869511"
}, {
  label: "NG Camp Perry Joint Training Center",
  value: "869037"
}, {
  label: "NG Camp Rapid",
  value: "869038"
}, {
  label: "NG Camp Ravenna Joint Military Training Center",
  value: "869196"
}, {
  label: "NG Camp Ripley",
  value: "869040"
}, {
  label: "NG Camp Sherman Joint Training Center",
  value: "869041"
}, {
  label: "NG Camp Smith Tng Site",
  value: "869042"
}, {
  label: "NG Camp Swift",
  value: "869043"
}, {
  label: "NG Camp Villere",
  value: "869044"
}, {
  label: "NG Camp Williams Tomah MTA",
  value: "869045"
}, {
  label: "NG Camp Withycombe Clackamas",
  value: "869046"
}, {
  label: "NG Carville Gillis Long Center",
  value: "869047"
}, {
  label: "NG Clarke Range Complex - Fort McClellan",
  value: "869268"
}, {
  label: "NG Collegeville FMS 4",
  value: "869269"
}, {
  label: "NG CTA Camp Mccain",
  value: "869049"
}, {
  label: "NG CTC Camp Dawson-Kingwood",
  value: "869050"
}, {
  label: "NG CTC Fort Custer Trng Center",
  value: "869051"
}, {
  label: "NG Cumming",
  value: "869264"
}, {
  label: "NG Duluth NG Armory",
  value: "869060"
}, {
  label: "NG Edgemeade TS Mtn Home",
  value: "869061"
}, {
  label: "NG Elkins AFRC",
  value: "869266"
}, {
  label: "NG Esler Field",
  value: "869063"
}, {
  label: "NG Ethan Allen AFB MTA",
  value: "869176"
}, {
  label: "NG Florence Military Reservation",
  value: "869064"
}, {
  label: "NG Floyd Edsall Training Center",
  value: "869065"
}, {
  label: "NG Fort Barfoot MTC & FMS 15",
  value: "869091"
}, {
  label: "NG Fort Chaffee MTC",
  value: "869073"
}, {
  label: "NG Fort Indiantown Gap",
  value: "869081"
}, {
  label: "NG Fort McClellan ARNG Tng Center",
  value: "869087"
}, {
  label: "NG Fort Wolters",
  value: "869099"
}, {
  label: "NG Fort Worth - Shoreview",
  value: "869100"
}, {
  label: "NG Frankfort Boone NG Center",
  value: "869101"
}, {
  label: "NG Ft Allen Rq 177",
  value: "869102"
}, {
  label: "NG Ft Ruger",
  value: "869103"
}, {
  label: "NG General Lucius D Clay National Guard Center",
  value: "869263"
}, {
  label: "NG Grand Island AASF/RC",
  value: "869105"
}, {
  label: "NG Grayling Airfield",
  value: "869107"
}, {
  label: "NG Greenlief TS/UTES 01",
  value: "869109"
}, {
  label: "NG Gulfport AVCRAD",
  value: "869110"
}, {
  label: "NG Hammond Airport",
  value: "869111"
}, {
  label: "NG Havre De Grace Military Res",
  value: "869112"
}, {
  label: "NG Helena Aviation RC- AASF- C12",
  value: "869114"
}, {
  label: "NG Hollidaysburg Readiness Center",
  value: "869116"
}, {
  label: "NG Hollis Plains Training Site",
  value: "869117"
}, {
  label: "NG Indianapolis 38 ID",
  value: "869121"
}, {
  label: "NG ITC Camp San Luis Obisbo",
  value: "869123"
}, {
  label: "NG Jackson Airport Armory",
  value: "869124"
}, {
  label: "NG Jackson Bks",
  value: "869125"
}, {
  label: "NG Jackson County AFRC",
  value: "869265"
}, {
  label: "NG JFHQ-Kansas",
  value: "869232"
}, {
  label: "NG Johnson City Gray",
  value: "869128"
}, {
  label: "NG Kalaeloa",
  value: "869130"
}, {
  label: "NG Knightstown",
  value: "869271"
}, {
  label: "NG Lane County AFRC FMS 5",
  value: "869135"
}, {
  label: "NG Lansing Joint Forces Readiness Center",
  value: "869136"
}, {
  label: "NG Las Cruces",
  value: "869137"
}, {
  label: "NG Lawrenceville",
  value: "869138"
}, {
  label: "NG Lexington AASF No 1",
  value: "869140"
}, {
  label: "NG Lincoln AASF/Readiness Center",
  value: "869141"
}, {
  label: "NG Los Alamitos JFTB",
  value: "869234"
}, {
  label: "NG Macon Readiness Center",
  value: "869145"
}, {
  label: "NG Macon TS",
  value: "869146"
}, {
  label: "NG Marseilles (MTA Tng Area)",
  value: "869148"
}, {
  label: "NG Martindale - AASF",
  value: "869149"
}, {
  label: "NG McConnelsville Training Site",
  value: "869151"
}, {
  label: "NG McNary Field Salem AASF",
  value: "869152"
}, {
  label: "NG Mead TS/FMS 06/Utes 02",
  value: "869153"
}, {
  label: "NG Mitchell Complex",
  value: "869155"
}, {
  label: "NG Montgomery JFHQ AFRC / CSMS 01",
  value: "869127"
}, {
  label: "NG MTA Camp Butner",
  value: "869259"
}, {
  label: "NG MTA Camp Clark Nevada",
  value: "869156"
}, {
  label: "NG MTA Camp Crowder Neosho",
  value: "869157"
}, {
  label: "NG MTA Camp Curtis Guil",
  value: "869158"
}, {
  label: "NG MTA Camp Edwards",
  value: "869159"
}, {
  label: "NG MTA Camp Fretterd",
  value: "869160"
}, {
  label: "NG MTA Camp Rilea",
  value: "869161"
}, {
  label: "NG MTA Camp Santiago",
  value: "869162"
}, {
  label: "NG MTA Clarks Hill Reservation",
  value: "869164"
}, {
  label: "NG MTA Ft Wm Henry Harrison",
  value: "869165"
}, {
  label: "NG MTA Gunpowder Military Reservation",
  value: "869166"
}, {
  label: "NG MTA Limestone Hills",
  value: "869167"
}, {
  label: "NG MTA-L Camp Williams",
  value: "869168"
}, {
  label: "NG MTC-H Camp Grayling",
  value: "869169"
}, {
  label: "NG MTC-H Camp Roberts",
  value: "869170"
}, {
  label: "NG MTCH Camp Guernsey",
  value: "869171"
}, {
  label: "NG Muscatatuck Urban Training Center",
  value: "869261"
}, {
  label: "NG Muskogee AFRC",
  value: "869267"
}, {
  label: "NG Nashville",
  value: "869172"
}, {
  label: "NG New Castle Readiness Center/FMS 9",
  value: "869173"
}, {
  label: "NG Norman CSMS",
  value: "869180"
}, {
  label: "NG Oglethorpe Armory",
  value: "869182"
}, {
  label: "NG Papago Military Reservation",
  value: "869185"
}, {
  label: "NG Picacho Aviation TS",
  value: "869188"
}, {
  label: "NG Raleigh",
  value: "869195"
}, {
  label: "NG Rickenbacker",
  value: "869201"
}, {
  label: "NG Rio Rancho TS",
  value: "869202"
}, {
  label: "NG River Road Training Site",
  value: "869177"
}, {
  label: "NG Rochester NGA and OMS 2",
  value: "869204"
}, {
  label: "NG Rosemount NG Armory",
  value: "869205"
}, {
  label: "NG RW Shepherd Hope Hull",
  value: "869193"
}, {
  label: "NG Saginaw",
  value: "869207"
}, {
  label: "NG Saint George/FMS 5B",
  value: "869208"
}, {
  label: "NG Salina KS Training Center",
  value: "869209"
}, {
  label: "NG Sandson AASF",
  value: "869000"
}, {
  label: "NG Santa Fe - Onate Complex TS",
  value: "869183"
}, {
  label: "NG Sea Girt  NJ NGTC",
  value: "869213"
}, {
  label: "NG Shelbyville",
  value: "869215"
}, {
  label: "NG Silver Bell Army Heliport",
  value: "869218"
}, {
  label: "NG Sioux Falls Benson Rd Complex",
  value: "869219"
}, {
  label: "NG Snake Creek TS Miramar",
  value: "868997"
}, {
  label: "NG Sparta Armory",
  value: "869223"
}, {
  label: "NG Spring City Readiness Center",
  value: "869224"
}, {
  label: "NG Springfield (TS Cp Lincoln)",
  value: "869225"
}, {
  label: "NG Springfield AVCRAD (New)",
  value: "869260"
}, {
  label: "NG St Cloud AASF",
  value: "869226"
}, {
  label: "NG St Cloud NG Armory",
  value: "869227"
}, {
  label: "NG State Military Reservation Camp Pendleton",
  value: "869036"
}, {
  label: "NG Stones Ranch Military Res",
  value: "869229"
}, {
  label: "NG Thunderbird Youth Academy",
  value: "869190"
}, {
  label: "NG Training Site",
  value: "869179"
}, {
  label: "NG TS Camp Johnson",
  value: "869236"
}, {
  label: "NG TS Ethan Allen Range",
  value: "869238"
}, {
  label: "NG TS Ike Skelton Jefferson City",
  value: "869239"
}, {
  label: "NG TS Keaukaha Mil Res",
  value: "869240"
}, {
  label: "NG TS Mark Twain Natl Forest Wapp",
  value: "869237"
}, {
  label: "NG Volkstone",
  value: "869245"
}, {
  label: "NG VTS Catoosa",
  value: "869246"
}, {
  label: "NG VTS Milan",
  value: "869276"
}, {
  label: "NG VTS Smyrna",
  value: "869247"
}, {
  label: "NG West Camp",
  value: "869252"
}, {
  label: "NG Westminster Training Site",
  value: "869254"
}, {
  label: "NG WH Ford Regional Tng Ctr",
  value: "869249"
}, {
  label: "NG Williamstown",
  value: "869186"
}, {
  label: "NG Youngstown Tng Site",
  value: "869178"
}, {
  label: "NGA Arnold Site",
  value: "869445"
}, {
  label: "Niagara Falls",
  value: "869341"
}, {
  label: "NMIC Suitland",
  value: "868912"
}, {
  label: "NNSY South Gate",
  value: "868831"
}, {
  label: "NNSY St Helena",
  value: "868862"
}, {
  label: "NOLF Brewton",
  value: "868974"
}, {
  label: "NOLF Choctaw",
  value: "868924"
}, {
  label: "NOLF Evergreen",
  value: "868921"
}, {
  label: "NOLF Holley",
  value: "868965"
}, {
  label: "NOLF Santa Rosa",
  value: "868918"
}, {
  label: "NOLF Silverhill",
  value: "868979"
}, {
  label: "NOLF Spencer",
  value: "868927"
}, {
  label: "NOLF Summerdale",
  value: "868949"
}, {
  label: "NOLF Wolf",
  value: "868925"
}, {
  label: "Northside Mid-South",
  value: "868957"
}, {
  label: "Northwest Chesapeake VA",
  value: "868865"
}, {
  label: "Norwalk 2",
  value: "869339"
}, {
  label: "NRC Solomons",
  value: "868914"
}, {
  label: "NSA Saratoga Springs NY",
  value: "868852"
}, {
  label: "NSGA Sabana Seca PR",
  value: "868990"
}, {
  label: "NSWC Carderock Div",
  value: "868970"
}, {
  label: "NWIRP Bedford MA",
  value: "868858"
}, {
  label: "Oak Grove",
  value: "869414"
}, {
  label: "Offutt Air Force Base",
  value: "869309"
}, {
  label: "Old Town Site 1",
  value: "868893"
}, {
  label: "OLF Atlantic",
  value: "869413"
}, {
  label: "OLF Bravo",
  value: "868928"
}, {
  label: "OLF Bronson",
  value: "868967"
}, {
  label: "OLF Coupeville",
  value: "869503"
}, {
  label: "OLF Whitehouse",
  value: "868978"
}, {
  label: "Oliktok Long Range Radar Site",
  value: "869329"
}, {
  label: "Orchard Combat Training Center",
  value: "869184"
}, {
  label: "Otis ANG Base",
  value: "869456"
}, {
  label: "Ozol Defense Fuel Support Point",
  value: "869526"
}, {
  label: "Pacific Missile Range Facility",
  value: "868902"
}, {
  label: "Parks Reserve Forces Tng Area",
  value: "869187"
}, {
  label: "Patrick Space Force Base",
  value: "869318"
}, {
  label: "Pearl City Annex",
  value: "869419"
}, {
  label: "Pease ANGB NH",
  value: "869403"
}, {
  label: "Pentagon",
  value: "869436"
}, {
  label: "Peterson Space Force Base",
  value: "869288"
}, {
  label: "Picatinny Arsenal",
  value: "869189"
}, {
  label: "Pico Del Este - AFWTF",
  value: "868989"
}, {
  label: "Pillar Point Space Force Station",
  value: "869296"
}, {
  label: "Pine Bluff Arsenal",
  value: "869525"
}, {
  label: "Piñon Canyon Maneuver Site",
  value: "868998"
}, {
  label: "Pittsburgh IAP ANG",
  value: "869379"
}, {
  label: "Pittsburgh IAP ARS",
  value: "869342"
}, {
  label: "Pohakuloa Training Area",
  value: "869504"
}, {
  label: "Poinsett Electronic Combat Range",
  value: "869286"
}, {
  label: "Point Arena Air Force Station",
  value: "869367"
}, {
  label: "Point Barrow Long Range Radar Site",
  value: "869333"
}, {
  label: "Point of Marsh Target",
  value: "869411"
}, {
  label: "Portland IAP ANG",
  value: "869397"
}, {
  label: "Portsmouth Naval Shipyard",
  value: "868850"
}, {
  label: "Presidio of Monterey",
  value: "869274"
}, {
  label: "Pueblo Chemical Depot",
  value: "869191"
}, {
  label: "Pumpkin Neck",
  value: "868911"
}, {
  label: "Pupukea Paalaa Uka Mil Road",
  value: "869192"
}, {
  label: "Puuloa-Oahu",
  value: "869417"
}, {
  label: "Quonset State Airport",
  value: "869400"
}, {
  label: "Radford Army Ammunition Plant",
  value: "869194"
}, {
  label: "Ramey AF Solar Observatory Research Site",
  value: "869480"
}, {
  label: "Randsburg Wash Area",
  value: "868931"
}, {
  label: "Red River Army Depot",
  value: "869197"
}, {
  label: "Redington Township",
  value: "868861"
}, {
  label: "Redstone Arsenal",
  value: "869198"
}, {
  label: "Reno Tahoe IAP",
  value: "869465"
}, {
  label: "Rickenbacker",
  value: "869399"
}, {
  label: "Rivanna Station",
  value: "869203"
}, {
  label: "Riverbank AAP",
  value: "869273"
}, {
  label: "Robins AFB",
  value: "869291"
}, {
  label: "Rock Island Arsenal",
  value: "869524"
}, {
  label: "Rocky Mountain Arsenal",
  value: "869523"
}, {
  label: "Rome Research Laboratory",
  value: "869477"
}, {
  label: "Rosecrans MAP (139AG)",
  value: "869489"
}, {
  label: "Runkle Stagefield AL",
  value: "869206"
}, {
  label: "Salt Lake City IAP",
  value: "869395"
}, {
  label: "San Clemente IS NALF",
  value: "868907"
}, {
  label: "San Nicolas Island",
  value: "868972"
}, {
  label: "San Pedro Fuel Depot",
  value: "868939"
}, {
  label: "Santa Cruz Island",
  value: "868905"
}, {
  label: "Saufley Field",
  value: "868943"
}, {
  label: "Savanna Depot Act",
  value: "869210"
}, {
  label: "Savannah/Hilton Head IAP",
  value: "869478"
}, {
  label: "Saylor Creek Air Force Range",
  value: "869277"
}, {
  label: "Schenectady ANG MAP",
  value: "869467"
}, {
  label: "Schriever Air Force Base",
  value: "869308"
}, {
  label: "Scott AFB",
  value: "869443"
}, {
  label: "Scranton AAP",
  value: "869212"
}, {
  label: "SDA Area",
  value: "868863"
}, {
  label: "Selfridge ANGB",
  value: "869401"
}, {
  label: "Sewage Evaporation Pond",
  value: "868899"
}, {
  label: "Seward Recreation Area",
  value: "869214"
}, {
  label: "Seymour Johnson Air Force Base",
  value: "869298"
}, {
  label: "Shaw Air Force Base",
  value: "869522"
}, {
  label: "Shell Basefield AL",
  value: "869216"
}, {
  label: "Sheppard AFB",
  value: "869521"
}, {
  label: "Shipyard Puget Sound",
  value: "869505"
}, {
  label: "Sierra Army Depot",
  value: "869217"
}, {
  label: "Silver Strand South",
  value: "868936"
}, {
  label: "Sioux Gateway Airport (ANG)",
  value: "869520"
}, {
  label: "Skelly Stagefield AL",
  value: "869220"
}, {
  label: "Smoky Hill ANG Range",
  value: "869393"
}, {
  label: "South Point",
  value: "868900"
}, {
  label: "Southeast Alaska Acoustic Measurement Facility",
  value: "868875"
}, {
  label: "Springfield Beckley",
  value: "869466"
}, {
  label: "St Juliens Creek East",
  value: "868859"
}, {
  label: "St Louis Air Force Station",
  value: "869444"
}, {
  label: "Stewart Annex",
  value: "868999"
}, {
  label: "Stewart IAP",
  value: "869402"
}, {
  label: "Stinson 5AB Stagefield AL",
  value: "869228"
}, {
  label: "Stump Neck Area",
  value: "868941"
}, {
  label: "Syracuse MCRC",
  value: "869425"
}, {
  label: "Tac X Stagefield AL",
  value: "869230"
}, {
  label: "Target 101 Shade Tree",
  value: "868933"
}, {
  label: "Target B-16",
  value: "868934"
}, {
  label: "Target B-17",
  value: "868881"
}, {
  label: "Target B-19",
  value: "868908"
}, {
  label: "Target B-20",
  value: "868954"
}, {
  label: "TBR Main",
  value: "869408"
}, {
  label: "The Barry M Goldwater Air Force Range",
  value: "869358"
}, {
  label: "The Farish Memorial Recreational Annex",
  value: "869324"
}, {
  label: "Tin City Long Range Radar Site",
  value: "869332"
}, {
  label: "Tinker Aerospace Complex",
  value: "869362"
}, {
  label: "Tinker Air Force Base",
  value: "869360"
}, {
  label: "Tinker Support Annex",
  value: "869361"
}, {
  label: "Tobyhanna Army Depot",
  value: "869231"
}, {
  label: "Toledo/Exp Airport",
  value: "869388"
}, {
  label: "Tooele Army Depot",
  value: "869519"
}, {
  label: "Tooele Army Depot South",
  value: "869056"
}, {
  label: "Toth Stagefield AL",
  value: "869233"
}, {
  label: "Travis Air Force Base",
  value: "869506"
}, {
  label: "Travis System Annex No 2",
  value: "869507"
}, {
  label: "Tripler Army Medical Center",
  value: "869508"
}, {
  label: "Tropic Regions Test Center",
  value: "869211"
}, {
  label: "Truax ANGB",
  value: "869389"
}, {
  label: "Truman Annex",
  value: "868910"
}, {
  label: "Trumbo Point Annex",
  value: "868961"
}, {
  label: "Tucson IAP",
  value: "869371"
}, {
  label: "Tulsa IAP",
  value: "869470"
}, {
  label: "Twin Cities AAP",
  value: "869518"
}, {
  label: "Tyndall Air Force Base",
  value: "869283"
}, {
  label: "U.S. Army Natick Soldier Systems Center",
  value: "869222"
}, {
  label: "Upper Yard Annapolis",
  value: "868946"
}, {
  label: "Us Army Joint Sys Mfg CtrLima",
  value: "869241"
}, {
  label: "USA Field Station Kunia",
  value: "869243"
}, {
  label: "USAF Academy",
  value: "869322"
}, {
  label: "USAF Academy Auxiliary Airfield",
  value: "869323"
}, {
  label: "Utah Test and Training Range",
  value: "869279"
}, {
  label: "Vance AFB",
  value: "869351"
}, {
  label: "Vandenberg Space Force Base",
  value: "869295"
}, {
  label: "Verona Defense Fuel Support Point",
  value: "869481"
}, {
  label: "Vieques West PR",
  value: "868986"
}, {
  label: "Volk ANGB",
  value: "869391"
}, {
  label: "W K Kellogg",
  value: "869383"
}, {
  label: "W Silver Spring Complex",
  value: "869248"
}, {
  label: "Waianae Kai Military Reservation",
  value: "869250"
}, {
  label: "Warner Springs Rts",
  value: "868880"
}, {
  label: "Waterfront",
  value: "868853"
}, {
  label: "Watervliet Arsenal",
  value: "869251"
}, {
  label: "Webster Field",
  value: "868948"
}, {
  label: "West Bank",
  value: "868975"
}, {
  label: "West Desert Test Center",
  value: "869059"
}, {
  label: "West Point Military Reservation",
  value: "869253"
}, {
  label: "Westover ARB",
  value: "869343"
}, {
  label: "Wheeler Army Airfield",
  value: "869255"
}, {
  label: "White Bluff",
  value: "869311"
}, {
  label: "White Sands Missile Range",
  value: "869256"
}, {
  label: "Whiteman AFB",
  value: "869316"
}, {
  label: "Will Rogers World Airport",
  value: "869468"
}, {
  label: "Woolmarket (De Soto)",
  value: "868926"
}, {
  label: "Wright-Patterson Air Force Base",
  value: "869301"
}, {
  label: "Yakima Training Center",
  value: "869572"
}, {
  label: "Yankee Target Range",
  value: "868964"
}, {
  label: "Yermo Area",
  value: "869406"
}, {
  label: "Yorktown Fuel Depot",
  value: "868856"
}, {
  label: "Youngstown",
  value: "869344"
}, {
  label: "Yukon Weapons Range",
  value: "869345"
}, {
  label: "Yuma Proving Ground",
  value: "869257"
}];
var options = {
  installations: installations
};

export { options as default, installations };
