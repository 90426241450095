import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';

var IMAGE_SIZES = ['small', 'medium', 'large', 'full'];
var POST_CARD_IMAGE_SIZES = {
  vertical: {
    xl: {
      lg_lte: 'medium',
      md_lte: 'medium',
      sm_lte: 'small'
    },
    lg: {
      lg_lte: 'medium',
      md_lte: 'medium',
      sm_lte: 'small'
    },
    md: {
      lg_lte: 'medium',
      md_lte: 'medium',
      sm_lte: 'small'
    },
    sm: {
      lg_lte: 'small',
      md_lte: 'small',
      sm_lte: 'small'
    }
  }
};
POST_CARD_IMAGE_SIZES.horizontal = Object.assign({}, POST_CARD_IMAGE_SIZES.vertical, {
  md: Object.assign({}, POST_CARD_IMAGE_SIZES.vertical.md, {
    lg_lte: 'small'
  })
});
function getPostCardImage(_ref) {
  var size = _ref.size,
    alignment = _ref.alignment,
    image = _ref.image;
  var sizeSlug = POST_CARD_IMAGE_SIZES[alignment][size];
  var defaultImage = findSmallestImageSize({
    image: image,
    sizeSlug: sizeSlug === null || sizeSlug === void 0 ? void 0 : sizeSlug.lg_lte
  });
  var responsiveImageSizes = Object.entries(sizeSlug).filter(function (value) {
    return value[0] !== 'lg_lte';
  }).reduce(function (acc, _ref2) {
    var _ref3 = _slicedToArray(_ref2, 2),
      breakpoint = _ref3[0],
      imageSize = _ref3[1];
    var availableSize = findSmallestImageSize({
      image: image,
      sizeSlug: imageSize
    });
    if (availableSize) {
      acc[breakpoint] = availableSize;
    }
    return acc;
  }, {});
  return Object.assign({}, defaultImage, {
    altText: image === null || image === void 0 ? void 0 : image.altText,
    sizes: responsiveImageSizes
  });
}
function findSmallestImageSize(_ref4) {
  var image = _ref4.image,
    sizeSlug = _ref4.sizeSlug;
  // Filter any smaller image sizes
  var indexOfRequestedSize = IMAGE_SIZES.indexOf(sizeSlug);
  var availableSizes = IMAGE_SIZES.slice(indexOfRequestedSize);
  for (var i = 0; i < availableSizes.length; i += 1) {
    var _image$mediaDetails;
    var imageSize = availableSizes[i];
    if (image !== null && image !== void 0 && (_image$mediaDetails = image.mediaDetails) !== null && _image$mediaDetails !== void 0 && _image$mediaDetails.sizes[imageSize]) {
      var _image$mediaDetails2;
      return image === null || image === void 0 || (_image$mediaDetails2 = image.mediaDetails) === null || _image$mediaDetails2 === void 0 ? void 0 : _image$mediaDetails2.sizes[imageSize];
    }
  }
  return null;
}

export { getPostCardImage };
