import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG } from '../../utils/zhl-calculator.js';

var FieldConfig = {
  homePrice: {
    name: 'homePrice',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  other: {
    name: 'other',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  percentage: {
    name: 'percentage'
  }
};
var useLastValidDownPaymentValues = function useLastValidDownPaymentValues(form) {
  return useLastValidValues(form, function (values) {
    return {
      fields: {
        homePrice: values.homePrice,
        percentage: values.percentage
      }
    };
  });
};

export { FieldConfig, useLastValidDownPaymentValues };
