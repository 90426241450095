import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import querystring from 'querystring';
import { useState, useEffect } from 'react';
import CalculationUtils__default from '@zillow/mortgage-calculations';
import { isWebview } from '@zillow/mortgage-webview-query-params';
import { LOAN_PROGRAMS, COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, LOAN_TERM_IN_MONTHS } from '../../utils/zhl-calculator.js';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import PropTypes from 'prop-types';

var ARM_PROGRAMS = [LOAN_PROGRAMS.arm3, LOAN_PROGRAMS.arm5, LOAN_PROGRAMS.arm7];
var DEFAULT_VALUES_SHAPE = {
  homePrice: PropTypes.number,
  zipCode: PropTypes.number,
  loanProgram: PropTypes.string,
  downPayment: PropTypes.number,
  interestRate: PropTypes.number,
  taxRate: PropTypes.number,
  homeInsurance: PropTypes.number,
  hoa: PropTypes.number
};
var FieldConfig = {
  homePrice: {
    name: 'homePrice',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPayment: {
    name: 'downPayment',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPaymentPercent: {
    name: 'downPaymentPercent',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  zipCode: {
    name: 'zipCode'
  },
  loanProgram: {
    name: 'loanProgram',
    options: [{
      value: LOAN_PROGRAMS.fixed30Year,
      label: '30-year fixed'
    }, {
      value: LOAN_PROGRAMS.fixed15Year,
      label: '15-year fixed'
    }, {
      value: LOAN_PROGRAMS.arm5,
      label: '5-year ARM'
    }],
    config: {
      required: 'Required',
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
            error = 'Invalid loan program';
          }
          return error;
        }
      }
    }
  },
  interestRate: {
    name: 'interestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  includeTaxesInsurance: {
    name: 'includeTaxesInsurance'
  },
  taxRate: {
    name: 'taxRate',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  taxRatePercent: {
    name: 'taxRatePercent',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  homeInsurance: {
    name: 'homeInsurance',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  hoa: {
    name: 'hoa',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  }
};
function useLastValidPayemntValues(form) {
  return useLastValidValues(form, getPayementValues);
}
function getPayementValues(lastValidFields) {
  var calculations = CalculationUtils__default({
    term: LOAN_TERM_IN_MONTHS[lastValidFields.loanProgram],
    homePrice: lastValidFields.homePrice,
    downPayment: lastValidFields.downPayment,
    rate: lastValidFields.interestRate,
    includeHOA: true,
    monthlyHOA: lastValidFields.hoa,
    includeHomeownersInsurance: lastValidFields.includeTaxesInsurance,
    annualHomeownersInsurance: lastValidFields.homeInsurance,
    includePropertyTax: lastValidFields.includeTaxesInsurance,
    propertyTaxAmount: lastValidFields.taxRate,
    propertyTaxRate: lastValidFields.taxRatePercent,
    includePMI: false,
    includeTaxesInsurance: true,
    isARM: ARM_PROGRAMS.includes(lastValidFields.loanProgram),
    dti: 0
  });
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
var getZillowURL = function getZillowURL(path) {
  var zillowOrigin = 'https://www.zillow.com';
  return zillowOrigin + "/" + path.replace(/^\//, '');
};
var useZillowURL = function useZillowURL(path) {
  var _useState = useState(getZillowURL(path)),
    _useState2 = _slicedToArray(_useState, 2),
    url = _useState2[0],
    setURL = _useState2[1];
  useEffect(function () {
    setURL(getZillowURL(path));
  }, [path]);
  return url;
};
function useCustomQuotesURL(pathBase, params) {
  if (pathBase === void 0) {
    pathBase = 'mortgage-rates';
  }
  if (params === void 0) {
    params = {};
  }
  var _useState3 = useState(pathBase + '/'),
    _useState4 = _slicedToArray(_useState3, 2),
    URI = _useState4[0],
    setURI = _useState4[1];
  useEffect(function () {
    var path = isWebview() ? 'mortgage/webviews/ZCQ.htm' : pathBase + '/';
    var queryString = querystring.stringify(_omitBy(params, _isNil));
    if (queryString) {
      queryString = "?" + queryString;
    }
    setURI(path + queryString);
  }, [pathBase, params]);
  return useZillowURL(URI);
}
var safe = function safe(fn, fallback) {
  if (fallback === void 0) {
    fallback = null;
  }
  return function (x) {
    return x ? fn(x) : fallback;
  };
};
function isSameFloat(float1, float2) {
  return Math.abs(Number(float1.toFixed(2)) - Number(float2.toFixed(2))) < Number.EPSILON;
}

export { DEFAULT_VALUES_SHAPE, FieldConfig, getPayementValues, getZillowURL, isSameFloat, safe, useCustomQuotesURL, useLastValidPayemntValues };
