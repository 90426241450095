import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import { useState } from 'react';
import useValidFieldUpdate from './useValidFieldUpdate.js';

function useLastValidValues(form, getValues) {
  var _useState = useState(function () {
      return getValues(form.getValues());
    }),
    _useState2 = _slicedToArray(_useState, 2),
    lastValidValues = _useState2[0],
    setLastValidValues = _useState2[1];
  useValidFieldUpdate(form, function (name, value) {
    return setLastValidValues(function (prevLastValidValues) {
      var _Object$assign;
      return getValues(Object.assign({}, prevLastValidValues.fields, (_Object$assign = {}, _Object$assign[name] = value, _Object$assign)));
    });
  });
  return lastValidValues;
}

export { useLastValidValues };
