import { inlineMediaQueryMixin, token } from '@zillow/constellation';

var ATTRIBUTES = ['background-color', 'font-color'];
var colorCardCSS = function colorCardCSS(props) {
  var styles = [];
  Object.keys(props).forEach(function (prop) {
    var attr = prop.replace(/[A-Z]/g, function (char) {
      return "-" + char.toLowerCase();
    });
    if (ATTRIBUTES.indexOf(attr) >= 0) {
      styles.push(inlineMediaQueryMixin(prop, function (mediaProps) {
        var value = mediaProps[prop];
        if (attr === 'font-color') {
          return token("colors." + value, function (color) {
            if (color) {
              return "color: " + color + ";";
            }
            return '';
          });
        }
        if (attr === 'background-color') {
          return token("colors." + value, function (color) {
            if (color) {
              return "background-color: " + color + ";";
            }
            return '';
          });
        }
        return attr + ": " + value + ";";
      })(props));
    }
  });
  return styles;
};

// TODO: figure out a way to use tokens here instead
/** @class GroupMixin */
/** @method */
var ColorCardMixin = function ColorCardMixin(props) {
  return [colorCardCSS(props)];
};

export { ColorCardMixin };
