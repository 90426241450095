import { useEffect } from 'react';

function useValidFieldUpdate(form, onValidFieldUpdate) {
  useEffect(function () {
    var _form$watch = form.watch(function (fields, _ref) {
        return new Promise(function ($return, $error) {
          var updatedFieldName, isFieldValid, name, value;
          updatedFieldName = _ref.name;
          return Promise.resolve(form.trigger(updatedFieldName)).then(function ($await_1) {
            try {
              isFieldValid = $await_1;
              if (isFieldValid) {
                name = updatedFieldName;
                value = fields[updatedFieldName];
                onValidFieldUpdate(name, value);
              }
              return $return();
            } catch ($boundEx) {
              return $error($boundEx);
            }
          }, $error);
        });
      }),
      unsubscribe = _form$watch.unsubscribe;
    return function () {
      unsubscribe();
    };
  },
  // Mount effect, so empty deps is ok
  // eslint-disable-next-line zillow/react-hooks/exhaustive-deps
  []);
}

export { useValidFieldUpdate as default };
