import PropTypes from 'prop-types';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { COMMON_AMOUNT_CONFIG } from '../../utils/zhl-calculator.js';

var FieldConfig = {
  annualIncome: {
    name: 'annualIncome',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  minCreditCardPayments: {
    name: 'minCreditCardPayments',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  carLoanPayments: {
    name: 'carLoanPayments',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  studentLoanPayments: {
    name: 'studentLoanPayments',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  alimonySupportPayments: {
    name: 'alimonySupportPayments',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  secondaryHomeExpenses: {
    name: 'secondaryHomeExpenses',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  otherDebtPayments: {
    name: 'otherDebtPayments',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  }
};
var DEFAULT_VALUES_SHAPE = {
  annualIncome: PropTypes.number,
  minCreditCardPayments: PropTypes.number,
  carLoanPayments: PropTypes.number,
  studentLoanPayments: PropTypes.number,
  alimonySupportPayments: PropTypes.number,
  secondaryHomeExpenses: PropTypes.number,
  otherDebtPayments: PropTypes.number
};
var RESULT_COPY = {
  VERY_GOOD: 'Your DTI is very good.',
  GOOD: 'Your DTI is good.',
  OK: 'Your DTI is OK.',
  OVER: 'Your DTI is over the limit.'
};
function useLastValidDTIValues(form) {
  return useLastValidValues(form, getDTIValues);
}
function getDTIValues(lastValidFields) {
  // Get monthly income from annual income
  var monthlyIncome = lastValidFields.annualIncome / 12;
  // Sum up total monthy debts
  var debtsOut = lastValidFields.minCreditCardPayments + lastValidFields.carLoanPayments + lastValidFields.studentLoanPayments + lastValidFields.alimonySupportPayments + lastValidFields.secondaryHomeExpenses + lastValidFields.otherDebtPayments;
  // Calculate Debt-to-income ratio
  // Multiply by 100 to format into percent value
  var dtiOut = debtsOut / monthlyIncome * 100;
  // Remaining $ from income after all debts are subtracted out
  var remainingOut = monthlyIncome - debtsOut;
  var calculations = {
    monthlyIncome: monthlyIncome,
    debtsOut: debtsOut,
    dtiOut: dtiOut,
    remainingOut: remainingOut
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
// As per referenced logic here : https://gitlab.zgtools.net/zgmi/static-mortgage/-/blob/main/src/calculator/dti/js/getDefaultMortgagePayment.js
//if DTIratio is below 30 we use 0.36 to get defaultMortgagePayment.
//and if it is between 30 & 50 we use 0.5 to get defaultMortgagePayment.
var getDefaultMortgagePayment = function getDefaultMortgagePayment(totalMonthlyDebts, monthlyIncome) {
  var DTIratio = totalMonthlyDebts / monthlyIncome * 100;
  if (DTIratio <= 30) {
    var defaultMortgagePayment = 0.36 * monthlyIncome - totalMonthlyDebts;
    return Math.round(defaultMortgagePayment);
  } else if (DTIratio > 30 && DTIratio <= 50) {
    var _defaultMortgagePayment = 0.5 * monthlyIncome - totalMonthlyDebts;
    return Math.round(_defaultMortgagePayment);
  }
  return 0;
};
var getRemainingMonthlyPayment = function getRemainingMonthlyPayment(monthlyIncome, debtsOut, mortgagePayment) {
  return monthlyIncome - debtsOut - mortgagePayment;
};
var getDTIMessageParts = function getDTIMessageParts(value, expanded) {
  var VERY_GOOD = RESULT_COPY.VERY_GOOD,
    GOOD = RESULT_COPY.GOOD,
    OK = RESULT_COPY.OK,
    OVER = RESULT_COPY.OVER;
  if (isNaN(value) || value <= 20) {
    return {
      result: VERY_GOOD,
      description: 'Having a DTI ratio of 36% or less is considered ideal, and anything under 20% is excellent.'
    };
  }
  if (value > 20 && value <= 36) {
    return {
      result: GOOD,
      description: 'Having a DTI ratio of 36% or less is considered ideal.'
    };
  }
  if (value > 36 && value <= 50) {
    return {
      result: OK,
      description: "It's under the 50% limit, but having a DTI ratio of 36% or less is considered ideal. Paying down debt or" + (expanded ? ' increasing your income can help improve your DTI ratio.' : '')
    };
  }
  return {
    result: OVER,
    description: "In most cases, 50% is the highest debt-to-income that lenders will allow. Paying down debt or" + (expanded ? ' increasing your income can help improve your DTI ratio.' : '')
  };
};

export { DEFAULT_VALUES_SHAPE, FieldConfig, RESULT_COPY, getDTIMessageParts, getDefaultMortgagePayment, getRemainingMonthlyPayment, useLastValidDTIValues };
