import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form';

var ApexFields = function ApexFields(_ref) {
  var children = _ref.children;
  var formStateProxy = useFormState();
  var formState = {
    isSubmitSuccessful: formStateProxy.isSubmitSuccessful
  };
  return !formState.isSubmitSuccessful && children;
};
ApexFields.propTypes = {
  children: PropTypes.node.isRequired
};
ApexFields.defaultProps = {};
var ApexFields$1 = ApexFields;

export { ApexFields$1 as default };
