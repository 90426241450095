import API, { createMAX } from '@zillow/mortgage-api-client';
import { getFullNameFromAbbr } from '@zillow/state-region-id-map';
import { getNextAppConfig } from '../block-helper.js';

var _PAGE_FLAVOR_LOAN_TYP;
var PAGE_FLAVOR = {
  Purchase: 'mortgage-rates',
  VA: 'va-mortgage-rates',
  VARefinance: 'va-refinance-rates',
  Refinance: 'refinance',
  Fixed30Year: '30-year-fixed',
  Fixed15Year: '15-year-fixed',
  Fixed20Year: '20-year-fixed',
  Fixed10Year: '10-year-fixed',
  ARM7: '7-year-arm',
  ARM5: '5-year-arm',
  ARM3: '3-year-arm',
  State: 'state',
  City: 'city',
  FHA: 'fha-mortgage-rates',
  Jumbo: 'jumbo-mortgage-rates',
  LoanType: 'PAGE_FLAVOR_LOAN_TYPE_MAP'
};
var LOAN_TYPES = {
  FHA: 'FHA',
  VA: 'VA',
  Conventional: 'Conventional',
  Jumbo: 'Jumbo'
};
var PAGE_FLAVOR_LOAN_TYPE_MAP = (_PAGE_FLAVOR_LOAN_TYP = {}, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.Purchase] = LOAN_TYPES.Conventional, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.VA] = LOAN_TYPES.VA, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.VARefinance] = LOAN_TYPES.VA, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.FHA] = LOAN_TYPES.FHA, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.Jumbo] = LOAN_TYPES.Jumbo, _PAGE_FLAVOR_LOAN_TYP);
var _zconfigs = {
  prod: {
    serverURLs: {
      MAX: 'https://mortgageapi.zillow.com'
    },
    partnerId: 'RD-WXDQSGN'
  },
  default: {
    serverURLs: {
      MAX: 'https://mortgageapi.qa.zillow.net'
    },
    partnerId: 'RD-GKZRBBL'
  }
};
var setServerUrl = function setServerUrl() {
  var _zconfig = _zconfigs[getNextAppConfig('NEXT_PUBLIC_ENV')] || _zconfigs.default;
  if (!window._zconfig) {
    window._zconfig = _zconfig;
  } else {
    var zconfig = window._zconfig;
    if (!zconfig.partnerId) {
      window._zconfig.partnerId = _zconfig.partnerId;
    }
    if (!zconfig.serverURLs) {
      zconfig.serverURLs = {
        MAX: _zconfig.serverURLs.MAX
      };
    } else if (!zconfig.serverURLs.MAX) {
      zconfig.serverURLs.MAX = _zconfig.serverURLs.MAX;
    }
  }
};
var formatCityFromLink = function formatCityFromLink(city) {
  return city && typeof city === 'string' && capitalize(city.replace(/-/g, ' '));
};
var capitalize = function capitalize(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
var createMax = function createMax() {
  if (!API.MAX) {
    if (!window._zconfig.serverURLs.MAX) {
      throw new Error('MAX serverURL is not set');
    }
    createMAX(window._zconfig.serverURLs.MAX);
  }
  return;
};
var stateAbbreviationValidator = function stateAbbreviationValidator(value) {
  return getFullNameFromAbbr(value) !== undefined;
};
var REGIONALIZED_PAGES = ['mortgage-rates', 'refinance', 'lender-directory'];
var DEFAULT_PARTNER_ID = 'RD-CZMBMCZ';

export { DEFAULT_PARTNER_ID, LOAN_TYPES, PAGE_FLAVOR, PAGE_FLAVOR_LOAN_TYPE_MAP, REGIONALIZED_PAGES, createMax, formatCityFromLink, setServerUrl, stateAbbreviationValidator };
