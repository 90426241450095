import PropTypes from 'prop-types';

// TODO: ask constellation to export this.
var inlineMediaQuery = function inlineMediaQuery(propType) {
  return PropTypes.oneOfType([propType, PropTypes.shape({
    default: propType,
    xs: propType,
    xs_lte: propType,
    xs_eq: propType,
    xs_gte: propType,
    sm: propType,
    sm_lte: propType,
    sm_eq: propType,
    sm_gte: propType,
    md: propType,
    md_lte: propType,
    md_eq: propType,
    md_gte: propType,
    lg: propType,
    lg_lte: propType,
    lg_eq: propType,
    lg_gte: propType,
    xl: propType,
    xl_lte: propType,
    xl_eq: propType,
    xl_gte: propType,
    xxl: propType,
    xxl_lte: propType,
    xxl_eq: propType,
    xxl_gte: propType
  })]);
};
var customPropTypes = {
  inlineMediaQuery: inlineMediaQuery
};

export { customPropTypes as default };
