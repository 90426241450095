import { track } from '@zillow/universal-analytics';

function trackLabel(label) {
  track({
    category: 'Mortgages',
    action: 'Affordability Calc',
    label: label
  }, {
    integrations: {
      GaPlugin: true
    }
  });
}

export { trackLabel };
