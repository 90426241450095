import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';

function getCookieValue(cookieName) {
  var cookieString = document.cookie;
  var cookies = cookieString.split('; ');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var _cookie$split = cookie.split('='),
      _cookie$split2 = _slicedToArray(_cookie$split, 2),
      name = _cookie$split2[0],
      value = _cookie$split2[1];
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

export { getCookieValue };
