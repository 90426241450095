import getConfig from 'next/config';

function getNextAppConfig(config) {
  var _ref = getConfig() || {},
    publicRuntimeConfig = _ref.publicRuntimeConfig,
    serverRuntimeConfig = _ref.serverRuntimeConfig;
  if (publicRuntimeConfig && typeof publicRuntimeConfig[config] !== 'undefined') {
    return publicRuntimeConfig[config];
  } else if (serverRuntimeConfig && typeof serverRuntimeConfig[config] !== 'undefined') {
    return serverRuntimeConfig[config];
  }
  return process.env[config];
}

export { getNextAppConfig };
