import { REGIONALIZED_PAGES, stateAbbreviationValidator, formatCityFromLink } from './mortgage-helpers.js';
import { getFullNameFromAbbr } from '@zillow/state-region-id-map';

// eslint-disable-next-line import/no-anonymous-default-export
var urlInfo = function urlInfo(pathname) {
  if (pathname === void 0) {
    pathname = typeof location !== 'undefined' && location.pathname;
  }
  var info = {};
  if (pathname) {
    var segments = pathname.split('/');
    info.page = segments[1];
    info.segments = segments.slice(1);
    if (info.segments[info.segments.length - 1] === '') {
      info.segments.pop();
    }
    if (REGIONALIZED_PAGES.indexOf(info.page) >= 0) {
      var modifier = segments[2];
      var stateAbbreviation = modifier && modifier.toUpperCase();
      if (stateAbbreviationValidator(stateAbbreviation)) {
        info.region = {
          path: modifier + '/',
          stateAbbreviation: stateAbbreviation,
          stateName: getFullNameFromAbbr(stateAbbreviation)
        };
        if (segments[3]) {
          info.region.path += segments[3] + '/';
          info.region.city = formatCityFromLink(segments[3]);
        }
      }
    }
  }
  return info;
};
var urlInfo$1 = urlInfo;

export { urlInfo$1 as default };
