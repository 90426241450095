import _isUndefined from 'lodash/isUndefined';
import _omitBy from 'lodash/omitBy';

// the order of keys matter. Example: If `sm` comes above `lg`, then `lg`
// is ignored due to CSS ordering.
function sortMediaQuery(mediaQueryObject) {
  return _omitBy({
    default: mediaQueryObject.default,
    xxl: mediaQueryObject.xxl,
    xxl_gte: mediaQueryObject.xxl_gte,
    xxl_eq: mediaQueryObject.xxl_eq,
    xxl_lte: mediaQueryObject.xxl_lte,
    xl: mediaQueryObject.xl,
    xl_gte: mediaQueryObject.xl_gte,
    xl_eq: mediaQueryObject.xl_eq,
    xl_lte: mediaQueryObject.xl_lte,
    lg: mediaQueryObject.lg,
    lg_gte: mediaQueryObject.lg_gte,
    lg_eq: mediaQueryObject.lg_eq,
    lg_lte: mediaQueryObject.lg_lte,
    md: mediaQueryObject.md,
    md_gte: mediaQueryObject.md_gte,
    md_eq: mediaQueryObject.md_eq,
    md_lte: mediaQueryObject.md_lte,
    sm: mediaQueryObject.sm,
    sm_gte: mediaQueryObject.sm_gte,
    sm_eq: mediaQueryObject.sm_eq,
    sm_lte: mediaQueryObject.sm_lte,
    xs: mediaQueryObject.xs,
    xs_gte: mediaQueryObject.xs_gte,
    xs_eq: mediaQueryObject.xs_eq,
    xs_lte: mediaQueryObject.xs_lte
  }, _isUndefined);
}

export { sortMediaQuery as default };
