var $schema = "https://schemas.wp.org/trunk/block.json";
var version = "1.0";
var name = "constellation/dti-calculator";
var title = "DTI calculator";
var description = "DTI calculator";
var icon = "businessperson";
var keywords = ["DTI", "calculator"];
var supports = {
  customClassName: false,
  html: false
};
var attributes = {
  annualIncome: {
    type: "number",
    label: "Annual income",
    "default": 7000,
    control: "number"
  },
  minCreditCardPayments: {
    type: "number",
    label: "Min. credit card payments",
    "default": 200,
    control: "number"
  },
  carLoanPayments: {
    type: "number",
    label: "Car loan payments",
    "default": 50,
    control: "number"
  },
  studentLoanPayments: {
    type: "number",
    label: "Student loan payments",
    "default": 300,
    control: "number"
  },
  alimonySupportPayments: {
    type: "number",
    label: "Alimony/child support payments",
    "default": 0,
    control: "number"
  },
  secondaryHomeExpenses: {
    type: "number",
    label: "Secondary home expenses",
    "default": 0,
    control: "number"
  },
  otherDebtPayments: {
    type: "number",
    label: "Other loan or debt payments",
    "default": 0,
    control: "number"
  }
};
var json = {
  $schema: $schema,
  version: version,
  name: name,
  title: title,
  description: description,
  icon: icon,
  keywords: keywords,
  supports: supports,
  attributes: attributes
};

export { $schema, attributes, json as default, description, icon, keywords, name, supports, title, version };
