var $schema = "https://schemas.wp.org/trunk/block.json";
var version = "1.0";
var name = "constellation/va-calculator";
var title = "VA calculator";
var description = "VA home loan calculator to estimate monthly mortgage payment with taxes and insurance.";
var icon = "businessperson";
var keywords = ["VA", "calculator"];
var supports = {
  customClassName: false,
  html: false
};
var attributes = {
  homeprice: {
    type: "number",
    label: "Home price",
    "default": 300000,
    control: "number"
  },
  loanProgram: {
    type: "number",
    label: "Loan program",
    "default": 360,
    control: "select",
    choices: [{
      label: "30 year fixed",
      value: 360
    }, {
      label: "15 year fixed",
      value: 180
    }, {
      label: "5-year ARM",
      value: 360
    }]
  },
  interestRate: {
    type: "number",
    label: "Interest rate",
    "default": 4.176,
    control: "number"
  },
  downPayment: {
    type: "number",
    label: "Down payment",
    "default": 60000,
    control: "number"
  },
  downPaymentPercent: {
    type: "number",
    label: "Down payment",
    "default": 20,
    control: "number"
  },
  homeInsurance: {
    type: "number",
    label: "Home insurance",
    "default": 1260,
    control: "number"
  },
  hoa: {
    type: "number",
    label: "HOA dues",
    "default": 0,
    control: "number"
  },
  taxRate: {
    type: "number",
    label: "Property tax",
    "default": 3600,
    control: "number"
  },
  taxRatePercent: {
    type: "number",
    label: "Property tax",
    "default": 1.2,
    control: "number"
  },
  includeTaxesInsurance: {
    type: "boolean",
    "default": "true",
    label: "Include taxes/insurance",
    control: "boolean"
  }
};
var json = {
  $schema: $schema,
  version: version,
  name: name,
  title: title,
  description: description,
  icon: icon,
  keywords: keywords,
  supports: supports,
  attributes: attributes
};

export { $schema, attributes, json as default, description, icon, keywords, name, supports, title, version };
