import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form';

var SimonSuccess = function SimonSuccess(_ref) {
  var children = _ref.children;
  var formStateProxy = useFormState();
  var formState = {
    isSubmitSuccessful: formStateProxy.isSubmitSuccessful
  };
  return formState.isSubmitSuccessful && children;
};
SimonSuccess.propTypes = {
  children: PropTypes.node.isRequired
};
SimonSuccess.defaultProps = {};
var SimonSuccess$1 = SimonSuccess;

export { SimonSuccess$1 as default };
