import { refinance } from '@zillow/mortgage-calculations';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { ThemeContext } from 'styled-components';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, getIsNanError, castAsNumberIfNotNan } from '../../utils/zhl-calculator.js';

var COMMON_TERM_CONFIG = {
  setValueAs: castAsNumberIfNotNan,
  max: {
    value: 500,
    message: "Must be 500 at most."
  },
  min: {
    value: 0,
    message: "Must be at least 0."
  },
  validate: {
    nan: getIsNanError('Must be a number.')
  }
};
var FieldConfig = {
  loanAmount: {
    name: 'loanAmount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  rate: {
    name: 'rate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  term: {
    name: 'term',
    config: Object.assign({
      required: 'Required'
    }, COMMON_TERM_CONFIG)
  },
  originationYear: {
    name: 'originationYear',
    config: {
      required: 'Required',
      min: {
        value: 1980,
        message: 'Must be at least 1980.'
      },
      validate: {
        isGreaterThanCurrentYearError: function isGreaterThanCurrentYearError(value) {
          var currentYear = new Date().getFullYear();
          var error = value > currentYear ? "Must be " + currentYear + " at most." : null;
          return error;
        },
        nan: getIsNanError('Must be a number.')
      }
    }
  },
  newLoanAmount: {
    name: 'newLoanAmount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  newTerm: {
    name: 'newTerm',
    config: Object.assign({
      required: 'Required'
    }, COMMON_TERM_CONFIG)
  },
  newRate: {
    name: 'newRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  fees: {
    name: 'fees',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  cashOut: {
    name: 'cashOut',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  rollFees: {
    name: 'rollFees'
  }
};
var DEFAULT_VALUES_SHAPE = {
  loanAmount: PropTypes.number,
  rate: PropTypes.number,
  term: PropTypes.number,
  originationYear: PropTypes.number,
  newLoanAmount: PropTypes.number,
  newTerm: PropTypes.number,
  newRate: PropTypes.number,
  fees: PropTypes.number,
  cashOut: PropTypes.number,
  rollFees: PropTypes.bool
};
function useLastValidRefinanceValues() {
  var form = useFormContext();
  return useLastValidValues(form, getRefinanceValues);
}
function getRefinanceValues(lastValidFields) {
  var calculations = refinance.default(lastValidFields);
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
function useRefinanceColors() {
  var theme = useContext(ThemeContext);
  return {
    lifetimeSavingsColor: theme.constellation.colors.green400,
    costsColor: theme.constellation.colors.red400,
    infoPointColor: theme.constellation.colors.brand
  };
}

/**
 * Returns an abbreviated string representation of number in form:
 *
 * @example
 * 999999 < abs(num) < Infinity => xxxM
 * 99999 < abs(num) < 999999 => xxxK
 * 0 <= abs(num) < 99999 => num
 *
 * @param {Number|String} num
 * @return {String} the formatted string
 */
function abbr(num) {
  var n = parseInt(num, 10);
  var v;
  var s;
  if (Number.isNaN(n)) {
    n = 0;
  }
  var an = Math.abs(n);
  if (an >= 1000000000) {
    v = parseInt(n / 100000000, 10) / 10;
    s = 'B';
  } else if (an >= 1000000) {
    v = parseInt(n / 100000, 10) / 10;
    s = 'M';
  } else if (an >= 1000) {
    v = parseInt(n / 100, 10) / 10;
    s = 'K';
  } else {
    v = parseInt(n, 10);
    s = '';
  }

  //  1000 => 1K
  //  2500 => 2.5K
  // 10000 => 10K
  // 12500 => 12K
  if (v >= 10) {
    v = parseInt(v, 10);
  }
  return v + s;
}

export { DEFAULT_VALUES_SHAPE, FieldConfig, abbr, useLastValidRefinanceValues, useRefinanceColors };
