import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form';

var ApexSuccess = function ApexSuccess(_ref) {
  var children = _ref.children;
  var formStateProxy = useFormState();
  var formState = {
    isSubmitSuccessful: formStateProxy.isSubmitSuccessful
  };
  return formState.isSubmitSuccessful && children;
};
ApexSuccess.propTypes = {
  children: PropTypes.node.isRequired
};
ApexSuccess.defaultProps = {};
var ApexSuccess$1 = ApexSuccess;

export { ApexSuccess$1 as default };
