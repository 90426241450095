import { inlineMediaQueryMixin } from '@zillow/constellation';

var videoMixin = function videoMixin(props) {
  var styles = [];
  if ('ratio' in props) {
    styles.push(inlineMediaQueryMixin('ratio', function (mediaProps) {
      return "max-width: 100%; height: auto; aspect-ratio: " + mediaProps.ratio + ";";
    })(props));
  }
  return styles;
};

export { videoMixin };
