import { inlineMediaQueryMixin, token, spaceMixin } from '@zillow/constellation';

var negativeSpaceMixin = function negativeSpaceMixin(space) {
  return token({
    spacing: 'spacing'
  }, function (_ref) {
    var _space$startsWith;
    var spacing = _ref.spacing;
    // This is sometimes undefined when rendering with ReactDOMServer.renderToString
    if (!spacing) {
      return spaceMixin(space);
    }
    var value = 0;
    if (space !== null && space !== void 0 && (_space$startsWith = space.startsWith) !== null && _space$startsWith !== void 0 && _space$startsWith.call(space, '-')) {
      var positiveSpace = space.substring(1);
      if (positiveSpace in spacing) {
        value = spacing[positiveSpace];
      }
      return "-" + value + "px";
    }
    return spaceMixin(space);
  });
};
var negativeMarginMixin = function negativeMarginMixin(props) {
  var styles = [];
  if ('margin' in props) {
    styles.push(inlineMediaQueryMixin('margin', function (mediaProps) {
      return "margin: " + negativeSpaceMixin(mediaProps.margin)(mediaProps) + ";";
    })(props));
  }
  if ('marginTop' in props) {
    styles.push(inlineMediaQueryMixin('marginTop', function (mediaProps) {
      return "margin-top: " + negativeSpaceMixin(mediaProps.marginTop)(mediaProps) + ";";
    })(props));
  }
  if ('marginBottom' in props) {
    styles.push(inlineMediaQueryMixin('marginBottom', function (mediaProps) {
      return "margin-bottom: " + negativeSpaceMixin(mediaProps.marginBottom)(mediaProps) + ";";
    })(props));
  }
  if ('marginRight' in props) {
    styles.push(inlineMediaQueryMixin('marginRight', function (mediaProps) {
      return "margin-right: " + negativeSpaceMixin(mediaProps.marginRight)(mediaProps) + ";";
    })(props));
  }
  if ('marginLeft' in props) {
    styles.push(inlineMediaQueryMixin('marginLeft', function (mediaProps) {
      return "margin-left: " + negativeSpaceMixin(mediaProps.marginLeft)(mediaProps) + ";";
    })(props));
  }
  return styles;
};

export { negativeMarginMixin, negativeSpaceMixin };
