import { useRef } from 'react';
import useValidFieldUpdate from './useValidFieldUpdate.js';

var UninitializedSymbol = Symbol('object is not yet loaded from local storage');
function useLocallyStoredObject(key, defaultValue, options) {
  if (options === void 0) {
    options = {
      disablePersistance: false
    };
  }
  var value = useRef(UninitializedSymbol);
  if (value.current === UninitializedSymbol) {
    var _global$window;
    // Read from storage into memory
    value.current = Object.assign({}, defaultValue, options.disablePersistance ? null : JSON.parse(((_global$window = global.window) === null || _global$window === void 0 || (_global$window = _global$window.localStorage) === null || _global$window === void 0 ? void 0 : _global$window.getItem(key)) || 'null'));
    if (!options.disablePersistance) {
      var _global$window2;
      // Write default value to storage
      (_global$window2 = global.window) === null || _global$window2 === void 0 || _global$window2.localStorage.setItem(key, JSON.stringify(value.current));
    }
  }
  return value.current;
}
function useStoreValidFieldsLocally(key, form, options) {
  if (options === void 0) {
    options = {
      disablePersistance: false
    };
  }
  var prevValues = useRef(UninitializedSymbol);
  useValidFieldUpdate(form, function (name, value) {
    var _Object$assign, _global$window4;
    if (options.disablePersistance) {
      return;
    }

    // To prevent overwriting old fields, read last valid fields from storage into memory
    if (prevValues.current === UninitializedSymbol) {
      var _global$window3;
      prevValues.current = JSON.parse(((_global$window3 = global.window) === null || _global$window3 === void 0 || (_global$window3 = _global$window3.localStorage) === null || _global$window3 === void 0 ? void 0 : _global$window3.getItem(key)) || 'null');
    }
    var nextValues = Object.assign({}, prevValues.current, (_Object$assign = {}, _Object$assign[name] = value, _Object$assign));
    prevValues.current = nextValues;
    (_global$window4 = global.window) === null || _global$window4 === void 0 || _global$window4.localStorage.setItem(key, JSON.stringify(nextValues));
  });
}

export { useLocallyStoredObject, useStoreValidFieldsLocally };
