import Head from 'next/head';
import { NEXT_PUBLIC_OPTIMIZELY_ID } from '../../../server/config';
import Script from 'next/script';

function OptimizelyScripts() {
    if (!NEXT_PUBLIC_OPTIMIZELY_ID) {
        return null;
    }

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    href={`https://www.zillowstatic.com/optimizely/${NEXT_PUBLIC_OPTIMIZELY_ID}.js`}
                    as="script"
                />
                <link rel="preconnect" href="//logx.optimizely.com" />
                <link
                    rel="prefetch"
                    href={`https://www.zillowstatic.com/optimizely/${NEXT_PUBLIC_OPTIMIZELY_ID}.js`}
                    as="script"
                />
            </Head>

            <Script
                id="optimizelyScript"
                strategy="beforeInteractive"
                src={`https://www.zillowstatic.com/optimizely/${NEXT_PUBLIC_OPTIMIZELY_ID}.js`}
            />
        </>
    );
}

export default OptimizelyScripts;
