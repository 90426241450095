/**
 * State names.
 */
var STATE_NAMES = ['United States', 'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'Washington D.C.'];
var PROGRAM_COLOR_CODE = {
  Fixed30Year: '#055E16',
  Fixed20Year: '#8A5700',
  Fixed15Year: '#001751',
  Fixed10Year: '#03698C',
  ARM7: '#933100',
  ARM5: '#5B33AD',
  ARM3: '#A3000B'
};
var PROGRAM_COLOR = {
  Fixed30Year: 'backgroundDarkGreen',
  Fixed20Year: 'backgroundDarkYellow',
  Fixed15Year: 'backgroundDarkBlue',
  Fixed10Year: 'backgroundDarkAqua',
  ARM7: 'backgroundDarkOrange',
  ARM5: 'backgroundDarkPurple',
  ARM3: 'backgroundDarkRed'
};
var LOAN_PROGRAMS = {
  Fixed30Year: '30 year fixed',
  Fixed20Year: '20 year fixed',
  Fixed15Year: '15 year fixed',
  Fixed10Year: '10 year fixed',
  ARM7: '7-year ARM',
  ARM5: '5-year ARM',
  ARM3: '3-year ARM'
};

export { LOAN_PROGRAMS, PROGRAM_COLOR, PROGRAM_COLOR_CODE, STATE_NAMES };
