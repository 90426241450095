import _isPlainObject from 'lodash/isPlainObject';

// Add an extra validation rule to an existing react-hook-form config.
// Even if "config" is undefined, it's ok.
function useConfigWithExtraValidation(config, getError) {
  var validate;
  if (config !== null && config !== void 0 && config.validate) {
    if (_isPlainObject(config.validate)) {
      validate = Object.assign({}, config.validate, {
        getError: getError
      });
    } else {
      // its a function
      validate = function validate(value) {
        return getError(value) || config.validate(value);
      };
    }
  } else {
    validate = getError;
  }
  var updatedConfig = Object.assign({}, config, {
    validate: validate
  });
  return updatedConfig;
}

export { useConfigWithExtraValidation as default };
