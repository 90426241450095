import { event } from '@zillow/universal-analytics';

/**
 * Triggers clickstream event of "click interaction" on tab.
 *
 * @param {Object} inputs
 * @param {String} inputs.label
 * @param {String} [inputs.id=undefined]
 * @param {Boolean} [inputs.isCarouselTab=undefined]
 *
 * @returns {void}
 */
function triggerTabClickEvent(inputs) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2048
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '1',
      event_template_id: '126',
      event_template_version_id: '1'
      // Master ID: 2284.2.126.1
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: inputs.isCarouselTab ? 'cms_carousel' : 'cms_tab',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: {
      block_id: inputs.id || '',
      block_type_cd: inputs.isCarouselTab ? 'carousel' : 'tab',
      block_displayed_txt: inputs.label
    }
  });
}

export { triggerTabClickEvent as default };
