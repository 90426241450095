import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import { useState, useCallback, useMemo } from 'react';

function useSizeDetector() {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    width = _useState2[0],
    setWidth = _useState2[1];
  var _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    height = _useState4[0],
    setHeight = _useState4[1];
  var ref = useCallback(function (element) {
    // React calls the ref callback with null when it unmounts
    if (!element) {
      return;
    }
    var boundingClientRect = element.getBoundingClientRect();
    setWidth(boundingClientRect.width);
    setHeight(boundingClientRect.height);
  }, []);
  return useMemo(function () {
    return {
      ref: ref,
      width: width,
      height: height
    };
  }, [ref, width, height]);
}

export { useSizeDetector as default };
