import { inlineMediaQueryMixin, token } from '@zillow/constellation';

var radiusMixin = function radiusMixin(radius) {
  return token({
    radii: 'radii'
  }, function (_ref) {
    var radii = _ref.radii;
    var value = 0;
    if (radii) {
      if (radius in radii) {
        value = radii[radius];
      }
    }

    // These tokens need a suffix
    if (Number.isInteger(value)) {
      return value + "px";
    }
    return value;
  });
};
var borderRadiusMixin = function borderRadiusMixin(props) {
  var styles = [];
  var addOverflow = false;
  if ('borderRadius' in props) {
    addOverflow = true;
    styles.push(inlineMediaQueryMixin('borderRadius', function (mediaProps) {
      return "border-radius: " + radiusMixin(mediaProps.borderRadius)(mediaProps) + ";";
    })(props));
  }
  if ('borderRadiusTopRight' in props) {
    addOverflow = true;
    styles.push(inlineMediaQueryMixin('borderRadiusTopRight', function (mediaProps) {
      return "border-top-right-radius: " + radiusMixin(mediaProps.borderRadiusTopRight)(mediaProps) + ";";
    })(props));
  }
  if ('borderRadiusTopLeft' in props) {
    addOverflow = true;
    styles.push(inlineMediaQueryMixin('borderRadiusTopLeft', function (mediaProps) {
      return "border-top-left-radius: " + radiusMixin(mediaProps.borderRadiusTopLeft)(mediaProps) + ";";
    })(props));
  }
  if ('borderRadiusBottomRight' in props) {
    addOverflow = true;
    styles.push(inlineMediaQueryMixin('borderRadiusBottomRight', function (mediaProps) {
      return "border-bottom-right-radius: " + radiusMixin(mediaProps.borderRadiusBottomRight)(mediaProps) + ";";
    })(props));
  }
  if ('borderRadiusBottomLeft' in props) {
    addOverflow = true;
    styles.push(inlineMediaQueryMixin('borderRadiusBottomLeft', function (mediaProps) {
      return "border-bottom-left-radius: " + radiusMixin(mediaProps.borderRadiusBottomLeft)(mediaProps) + ";";
    })(props));
  }
  if (addOverflow) {
    styles.push('overflow: hidden;');
  }
  return styles;
};

export { borderRadiusMixin, radiusMixin };
