import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

/**
 * Renders children if the field with name of `name` has the current value of `value`.
 */
function CondtionalField(_ref) {
  var name = _ref.name,
    value = _ref.value,
    children = _ref.children;
  var currentValue = useWatch({
    name: name
  });
  return currentValue === value ? children : null;
}
CondtionalField.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  // eslint-disable-next-line zillow/react/forbid-prop-types
  value: PropTypes.any
};

export { CondtionalField as default };
