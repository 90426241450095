import _invert2 from 'lodash/invert';
import urlInfo from './urlInfo.js';
import { PAGE_FLAVOR } from './mortgage-helpers.js';

var MortgagePageClassification = function MortgagePageClassification(pathname) {
  // Defaults
  this.flavor = PAGE_FLAVOR.Purchase;
  this.refinance = false;
  this.loanType = undefined;
  if (pathname) {
    var info = urlInfo(pathname);
    this.segments = info.segments;
    this.refinance = info.page === PAGE_FLAVOR.Refinance || info.segments[1] === PAGE_FLAVOR.VARefinance;
    if (info.page === PAGE_FLAVOR.Purchase || info.page === PAGE_FLAVOR.Refinance) {
      this.loanType = this.refinance ? 'refinance' : info.segments.includes('purchase') ? 'purchase' : undefined;
      if (info.region && info.region.stateAbbreviation) {
        this.stateAbbreviation = info.region.stateAbbreviation;
        this.stateName = info.region.stateName;
        if (info.region.city) {
          // City flavor
          this.flavor = PAGE_FLAVOR.City;
          this.city = info.region.city;
        } else {
          // State flavor
          this.flavor = PAGE_FLAVOR.State;
        }
      } else if (_invert2(PAGE_FLAVOR)[info.segments[1]]) {
        // Program flavor
        this.flavor = info.segments[1];
      } else {
        // Purchase/Refinance without city/state
        this.flavor = info.page;
      }
    }
  }
};

export { MortgagePageClassification as default };
