import _debounce from 'lodash/debounce';
import { event } from '@zillow/universal-analytics';

// Handle field change, debounced by 3 seconds.
var handleFieldChangeEvent = _debounce(function (label, blockType) {
  sendInteractionEvent(label, blockType);
}, 3000);
var sendInteractionEvent = function sendInteractionEvent(label, blockType) {
  var newLaneData = {
    envelope: {
      event_type_id: '2056',
      event_type_version_id: '2',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: blockType == 'form_field' ? 'cms_form' : blockType == 'slider' ? 'slider' : 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: {
      block_type_cd: blockType,
      block_id: 'dti_calc',
      block_displayed_txt: label
    }
  };
  event(newLaneData);
};

export { handleFieldChangeEvent };
