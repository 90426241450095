import _debounce from 'lodash/debounce';
import { event } from '@zillow/universal-analytics';
import { FORM_FIELD, BLOCK_ID } from './constants.js';

// Handle field change, debounced by 3 seconds.
var handleFieldChangeEvent = _debounce(function (label, block_type) {
  sendInteractionEvent(label, block_type);
}, 3000);
var handleFieldClickEvent = _debounce(function (label, block_type) {
  sendInteractionClickEvent(label, block_type);
}, 3000);
var sendInteractionEvent = function sendInteractionEvent(label, block_type) {
  var newLaneData = {
    envelope: {
      event_type_id: '2056',
      event_type_version_id: '2',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: block_type === FORM_FIELD ? 'cms_form' : block_type === 'slider' ? 'cms_slider' : 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: {
      block_type_cd: block_type,
      block_id: BLOCK_ID,
      block_displayed_txt: label
    }
  };
  event(newLaneData);
};
var sendInteractionClickEvent = function sendInteractionClickEvent(label, block_type) {
  var newLaneData = {
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: {
      block_type_cd: block_type,
      block_id: BLOCK_ID,
      block_displayed_txt: label
    }
  };
  event(newLaneData);
};
var legalPopoverInteractionEventCmsInfo = {
  block_type_cd: 'modal_dialog',
  block_id: BLOCK_ID,
  block_displayed_txt: 'Calculator disclaimer'
};

export { handleFieldChangeEvent, handleFieldClickEvent, legalPopoverInteractionEventCmsInfo };
