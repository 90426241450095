var _LOAN_TERM_IN_MONTHS;
/**
 * Takes number like `10000.10` and returns "$10,000".
 */
function getWholeDollars(dollarFloat) {
  return dollarFloat.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
}
function getCommaSeparatedNumber(number) {
  return number.toLocaleString('en-US', {
    style: 'decimal'
  });
}
function getIsNanError(customMessage) {
  return function getIsNanErrorHelper(value) {
    var error = null;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
      error = customMessage;
    }
    return error;
  };
}
function getIsAmountNanError(customMessage) {
  return function getIsAmountNanErrorHelper(value) {
    var error = null;
    if (value && typeof value === 'string') {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(amountAsNumber(value))) {
        error = customMessage;
      }
    }
    return error;
  };
}

// If user types commas, this replaces it, and turns it into a number.
function amountAsNumber(amount) {
  return typeof amount === 'string' ? Number(amount.trim().replace(/,/g, '')) : amount;
}

// If the value can be turned into a number it will return the number of
// it, else it will leave it as is.
function castAsNumberIfNotNan(value) {
  if (typeof value === 'string' && value !== '') {
    var casted = Number(value.trim().replace(/,/g, ''));
    if (!Number.isNaN(casted)) {
      return casted;
    }
  }
  return value;
}
var MAX_DOLLARS = 100000000;
var COMMON_AMOUNT_CONFIG = {
  required: 'Required',
  max: {
    value: MAX_DOLLARS,
    message: "Must be " + getWholeDollars(MAX_DOLLARS) + " at most."
  },
  min: {
    value: 0,
    message: 'Must be at least $0.'
  },
  validate: {
    nan: getIsAmountNanError('Must be numeric amount.')
  },
  setValueAs: castAsNumberIfNotNan
};
var COMMON_PERCENT_CONFIG = {
  required: 'Required',
  max: {
    value: 100,
    message: 'Must be 100% at most.'
  },
  min: {
    value: 0,
    message: 'Must be at least 0%.'
  },
  validate: {
    nan: getIsNanError('Must be numeric percentage.')
  },
  setValueAs: castAsNumberIfNotNan
};

// eslint-disable-next-line consistent-return
function commaSeparateNumber(value) {
  if (value) {
    var trimmedValue = typeof value === 'string' ? value.trim() : String(value);
    if (trimmedValue) {
      var numeric = amountAsNumber(trimmedValue);
      if (Number.isNaN(numeric)) {
        return value;
      }
      return getCommaSeparatedNumber(numeric);
    }
  }
  return value;
}
var LOAN_PROGRAMS = {
  fixed30Year: 'Fixed30Year',
  fixed20Year: 'Fixed20Year',
  fixed15Year: 'Fixed15Year',
  fixed10Year: 'Fixed10Year',
  arm3: 'ARM3',
  arm5: 'ARM5',
  arm7: 'ARM7',
  homeEquity30Year: 'HomeEquity30Year',
  homeEquity30YearDueIn15: 'HomeEquity30YearDueIn15',
  homeEquity15Year: 'HomeEquity15Year',
  heloc20Year: 'HELOC20Year',
  heloc15Year: 'HELOC15Year',
  heloc10Year: 'HELOC10Year',
  lowOrNoDown: 'LowOrNoDown',
  interestOnly: 'InterestOnly'
};
var LOAN_TERM_IN_MONTHS = (_LOAN_TERM_IN_MONTHS = {}, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.fixed30Year] = 360, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.fixed20Year] = 240, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.fixed15Year] = 180, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.fixed10Year] = 120, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.arm3] = 360, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.arm5] = 360, _LOAN_TERM_IN_MONTHS[LOAN_PROGRAMS.arm7] = 360, _LOAN_TERM_IN_MONTHS);

export { COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, LOAN_PROGRAMS, LOAN_TERM_IN_MONTHS, MAX_DOLLARS, amountAsNumber, castAsNumberIfNotNan, commaSeparateNumber, getCommaSeparatedNumber, getIsAmountNanError, getIsNanError, getWholeDollars };
